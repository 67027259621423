import React, { useEffect, useState } from 'react';
import Images from '../../../component_system/components/preload/images';

interface EffectIconWithCountdownProps {
    icon: string;
    alt?: string;
    iconSize?: number;
    expirationTime: Date;
}

const EffectIconWithCountdown: React.FC<EffectIconWithCountdownProps> = ({
    icon,
    alt = 'icon',
    iconSize = 24,
    expirationTime,
}) => {
    const [remainingTime, setRemainingTime] = useState<number>(
        Math.max(0, Math.floor((expirationTime.getTime() - Date.now()) / 1000))
    );

    useEffect(() => {
        const interval = setInterval(() => {
            const timeLeft = Math.max(
                0,
                Math.floor((expirationTime.getTime() - Date.now()) / 1000)
            );
            setRemainingTime(timeLeft);

            if (timeLeft === 0) {
                clearInterval(interval);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [expirationTime]);

    return (
        <div
            style={{
                position: 'relative',
                display: 'inline-block',
                width: `${iconSize}px`,
                height: `${iconSize}px`,
            }}
        >
            <img
                src={icon || Images.Icons.SpeedBoost}
                alt={alt}
                style={{
                    width: `${iconSize}px`,
                    height: `${iconSize}px`,
                }}
            />
            {remainingTime > 0 && (
                <div
                    style={{
                        position: 'absolute',
                        bottom: iconSize * 0.1,
                        right: iconSize * 0.1,
                        background: 'linear-gradient(0deg, #f48e24, #f1e40c)',
                        color: 'white',
                        fontSize: `${iconSize * 0.4}px`,
                        padding: '2px',
                        paddingLeft: '4px',
                        paddingRight: '4px',
                        borderRadius: '3px',
                        transform: 'translate(50%, 50%)',
                        textShadow: '0px 3px 0px rgba(0,0,0,0.4)',
                    }}
                >
                    {remainingTime}
                </div>
            )}
        </div>
    );
};

export default EffectIconWithCountdown;
