import { constants } from '../../utils/constants';
import StandardHttpAPI from '../standard_http_api';
import ValueContainer from '../../utils/value_container';
import CompactJsonOffer from '../offer_api/compact_json_offer';
import AcquiredOffer from '../offer_api/acquired_offer';
import StandardHttpResponse from '../models/standard_http_response';
import MineClick from '../models/mine_click';
import { Item } from '../customization_api/item';

const API_URL = constants.API_URL;

class BubbleApi {
    // LAST_PLAYABLE_TOO_FAR_AWAY - Only happens if the user has since reached the bubble illegally. If they reached it legally
    // the last position should be in range of the bubble
    // INVALID_PLAYABLE_GROUP - Only happens if user never had a playable group before
    /**
     * Check if the user can open a bubble
     * @param bubbleId - The bubble id
     * @returns A promise that resolves to a StandardHttpResponse
     */
    static async canOpenBubble(
        bubbleId: number | string
    ): Promise<StandardHttpResponse> {
        return await StandardHttpAPI.get({
            uri: `${API_URL}bubble/${bubbleId}/canOpen`,
            bearer: ValueContainer.token,
        });
    }

    static async getOffersForBubble(
        bubbleId: number | string
    ): Promise<CompactJsonOffer[]> {
        const response = await StandardHttpAPI.get({
            uri: `${API_URL}bubble/${bubbleId}/offers`,
            bearer: ValueContainer.token,
        });

        if (!response.isSuccess) {
            return Promise.reject(response);
        }

        return response.response.map((json: any) =>
            CompactJsonOffer.fromJsonLowerCamelCase(json)
        );
    }

    static async purchaseOffer(
        offerId: string,
        userAgent: string,
        innerScreenHeight: number,
        innerScreenWidth: number
    ): Promise<AcquiredOffer> {
        const response = await StandardHttpAPI.post({
            uri: `${API_URL}bubble/purchase`,
            bearer: ValueContainer.token,
            body: {
                offerId: offerId,
                userAgent: userAgent,
                innerScreenHeight: innerScreenHeight,
                innerScreenWidth: innerScreenWidth,
            },
        });

        if (!response.isSuccess) {
            return Promise.reject(response);
        }

        return AcquiredOffer.fromJsonLowerCamelCase(response.response);
    }

    static async registerMineClicks(
        acquiredOfferId: string,
        clicks: MineClick[]
    ): Promise<void> {
        await StandardHttpAPI.post({
            uri: `${API_URL}bubble/registerMineClicks`,
            bearer: ValueContainer.token,
            body: {
                acquiredOfferId: acquiredOfferId,
                clicks: clicks.map((click) => click.toJsonLowerCamelCase()),
            },
        });
    }

    static async openLootBox(
        acquiredOfferId: string
    ): Promise<Item | AcquiredOffer> {
        const response = await StandardHttpAPI.post({
            uri: `${API_URL}bubble/vault/openLootBox/${acquiredOfferId}`,
            bearer: ValueContainer.token,
            body: null,
        });

        if (!response.isSuccess) {
            return Promise.reject(response);
        }

        if (response.response.acquiredOffer) {
            return AcquiredOffer.fromJsonLowerCamelCase(
                response.response.acquiredOffer
            );
        }

        return Item.fromJsonLowerCamelCase(response.response.item);
    }
}

export default BubbleApi;
