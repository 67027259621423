import Decimal from "decimal.js";

class CryptoCurrencyInfo {
    name: string;
    description: string;
    coverUrl: string;
    blockchainType: string;
    network: string;
    quotePriceUSD: Decimal;
    minClaimableUSDAmount: Decimal;
    cryptoCurrency: string;
    cryptoCurrencyAmount: number | undefined;
  

    constructor(
        name: string,
        description: string,
        coverUrl: string,
        blockchainType: string,
        network: string,
        quotePriceUSD: Decimal,
        minClaimableUSDAmount: Decimal,
        cryptoCurrency: string,
        cryptoCurrencyAmount?: number,       

    ) {
        this.name = name;
        this.description = description;
        this.coverUrl = coverUrl;
        this.blockchainType = blockchainType;
        this.network = network;
        this.cryptoCurrency = cryptoCurrency;
        this.cryptoCurrencyAmount = cryptoCurrencyAmount;
        this.quotePriceUSD = quotePriceUSD;
        this.minClaimableUSDAmount = minClaimableUSDAmount;
    }

    static fromJsonUpperCamelCase(json: {
        [key: string]: any;
    }): CryptoCurrencyInfo {
        return new CryptoCurrencyInfo(
            json['Name'],
            json['Description'],
            json['CoverUrl'],
            json['BlockchainType'],
            json['Network'],
            new Decimal(json['QuotePriceUSD']),
            new Decimal(json['MinClaimableUSDAmount']),
            json['CryptoCurrency'],
            json['CryptoCurrencyAmount']
        );
    }

    static fromJsonLowerCamelCase(json: {
        [key: string]: any;
    }): CryptoCurrencyInfo {
        return new CryptoCurrencyInfo(
            json['name'],
            json['description'],
            json['coverUrl'],
            json['blockchainType'],
            json['network'],
            new Decimal(json['quotePriceUSD']),
            new Decimal(json['minClaimableUSDAmount']),
            json['cryptoCurrency'],
            json['cryptoCurrencyAmount']
        );
    }
}

export default CryptoCurrencyInfo;
