export enum ClaimStatus {
    Requested = 'Requested', 
    Processing =  'Processing', 
    Completed = 'Completed',
    Rejected = 'Rejected',
    WaitingUserAction = 'WaitingUserAction',

}

class ClaimedOffer {
    cryptoCurrency: string; 
    cryptoCurrencyAmount: number; 
    coverUrl: string;
    status: ClaimStatus; // Status of the claim
    statusMessage: string; // Status message, e.g., reason for rejection

    constructor({
        cryptoCurrency,
        cryptoCurrencyAmount,
        coverUrl,
        status,
        statusMessage,
    } :{
        cryptoCurrency: string,
        cryptoCurrencyAmount: number,
        coverUrl: string,
        status: ClaimStatus,
        statusMessage: string
    }) {
        this.cryptoCurrency = cryptoCurrency;
        this.cryptoCurrencyAmount = cryptoCurrencyAmount;
        this.coverUrl = coverUrl;
        this.status = status;
        this.statusMessage = statusMessage;
    }

    static fromJsonLowerCamelCase(json: any): ClaimedOffer {
        return new ClaimedOffer({
            cryptoCurrency: json.cryptoCurrency,
            cryptoCurrencyAmount: json.cryptoCurrencyAmount,
            coverUrl: json.coverUrl,
            status: json.status,
            statusMessage: json.statusMessage,
        });
    }

    static fromJsonUpperCase(json: any): ClaimedOffer {
        return new ClaimedOffer({
            cryptoCurrency: json.CryptoCurrency,
            cryptoCurrencyAmount: json.CryptoCurrencyAmount,
            coverUrl: json.CoverUrl,
            status: json.Status,
            statusMessage: json.StatusMessage,
        });
    }
}

export default ClaimedOffer;