import { Item } from './item';
import CompactJsonOffer from '../offer_api/compact_json_offer';

class LootBoxInformation {
    possibleItems: Item[];
    possibleOffers: CompactJsonOffer[];

    constructor(lootBoxInformation: { [key: string]: any }) {
        this.possibleItems = lootBoxInformation['possibleItems'].map(
            (item: { [key: string]: any }) => new Item(item)
        );
        this.possibleOffers = lootBoxInformation['possibleOffers'].map(
            (offer: { [key: string]: any }) => new CompactJsonOffer(offer)
        );
    }

    static fromJsonLowerCamelCase(json: {
        [key: string]: any;
    }): LootBoxInformation {
        return new LootBoxInformation({
            possibleItems: json['possibleItems'],
            possibleOffers: json['possibleOffers'],
        });
    }

    static fromJsonUpperCamelCase(json: {
        [key: string]: any;
    }): LootBoxInformation {
        return new LootBoxInformation({
            possibleItems: json['PossibleItems'],
            possibleOffers: json['PossibleOffers'],
        });
    }
}

export default LootBoxInformation;
