import Task from "../../../apis/models/task/task";
import Images from "../../../component_system/components/preload/images";
import QuestItem from "./quest_item";
import styles from './quest_partner.module.css';
import stylesChallenge from './quest_partner_challenge.module.css';

type QuestPartnerProps = {
    partnerName: string;
    tasks: Task[];
    handleTaskAction: (task:Task)=>Promise<void>;
  };

const QuestPartner = ({partnerName, tasks, handleTaskAction} : QuestPartnerProps) => {
    
    const sectionClass = `${styles.questSection} ${stylesChallenge[partnerName]}`;
    const overlayClass = `${styles.overlay} ${stylesChallenge[`${partnerName}Overlay`]}`;
    const contentClass = `${styles.content} ${stylesChallenge[`${partnerName}Content`]}`;

    function getBackground(partnerName: string) {
        switch (partnerName) {
            case 'challenge':
                return Images.Partners.ChallengeBanner;
            case 'brett':
                return Images.Partners.BrettBanner;
            case 'turbo':
                return Images.Partners.TurboBanner;
            default:
                return "";
        }
    }


    function getPartnerLogo(partnerName: string): string | undefined {
        switch (partnerName) {
            case 'challenge':
                return Images.Partners.ChallengeLogo;
            case 'brett':
                return Images.Partners.BrettLogo;
            case 'turbo':
                return Images.Partners.TurboLogo;
            default:
                return undefined;
        }
    }

    return (
        <div className={sectionClass}>
            <div className={styles.partnerHero} style={{backgroundImage:`url(${getBackground(partnerName)})`}}>
                <div className={overlayClass}>
                    <div className={contentClass}>
                    <img src={getPartnerLogo(partnerName)} className={styles.partnerLogo} alt="" />
                    <p>Partner Quests</p>
                    </div>
                </div>
            </div>
            {tasks.map((quest: Task, index) => (
                <QuestItem key={index}
                    partnerName={partnerName}
                    task={quest}
                    action={() => handleTaskAction(quest)}
                />
            ))}
        </div>
      )
}

export default QuestPartner;