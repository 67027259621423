import Component from '../../component_container/models/component';
import ComponentError from '../../component_container/models/component_error';
import Completer from '../../component_container/utilities/completer';
import LocationComponent from '../location/location_component';
import AuthenticationComponent from '../authentication/authentication_component';
import ValueContainer from '../../../utils/value_container';
import Color from '../../../utils/color';
import uuid from '../../../utils/uuid';
import SettingsComponent from '../settings/settings_component';
import ContainerHelper from '../../component_container/utilities/container_helper';
import UnityCameraMode from './unity_camera_mode';
import * as Sentry from '@sentry/react';

type UnityEventListener = (event: string, args: any[]) => void;
type CameraModeSubscription = (
    previousCameraMode: UnityCameraMode,
    cameraMode: UnityCameraMode
) => void;

class UnityComponent extends Component {
    private _unityLoadCompleter: Completer<void> = new Completer<void>();
    private _gameLoadCompleter: Completer<void> = new Completer<void>();

    private _pendingRequests: Map<string, Completer<any>> = new Map();

    private _eventListeners: UnityEventListener[] = [];

    addEventListener(listener: UnityEventListener): void {
        this._eventListeners.push(listener);
    }

    removeEventListener(listener: UnityEventListener): void {
        this._eventListeners = this._eventListeners.filter(
            (l) => l !== listener
        );
    }

    private _cameraModeSubscriptions: CameraModeSubscription[] = [];

    addCameraModeSubscription(subscription: CameraModeSubscription): void {
        this._cameraModeSubscriptions.push(subscription);
    }

    removeCameraModeSubscription(subscription: CameraModeSubscription): void {
        this._cameraModeSubscriptions = this._cameraModeSubscriptions.filter(
            (s) => s !== subscription
        );
    }

    private _notifyCameraModeSubscriptions(
        fromCameraMode: UnityCameraMode,
        cameraMode: UnityCameraMode
    ): void {
        this._cameraModeSubscriptions.forEach((subscription) =>
            subscription(fromCameraMode, cameraMode)
        );
    }

    private _cameraMode: UnityCameraMode = UnityCameraMode.FOLLOW;
    get cameraMode(): UnityCameraMode {
        return this._cameraMode;
    }

    private _defaultEventListener: UnityEventListener = (event, args) => {
        if (event === 'map:camera:mode:changed') {
            this._cameraMode = args[0] as UnityCameraMode;
            const fromCameraMode = args[1] as UnityCameraMode;
            this._notifyCameraModeSubscriptions(
                fromCameraMode,
                this._cameraMode
            );
        } else if (event === 'system:log:error') {
            const logString = args[0] as string;
            const stackTrace = args[1] as string;
            Sentry.captureException(
                `Unity Error: ${logString} --- ${stackTrace}`
            );
        }
    };

    private _sendMessage: (
        gameObjectName: string,
        methodName: string,
        parameter: any
    ) => void = () => {};

    set sendMessage(
        value: (
            gameObjectName: string,
            methodName: string,
            parameter: any
        ) => void
    ) {
        this._sendMessage = value;
    }

    get unityLoadCompleter(): Completer<void> {
        return this._unityLoadCompleter;
    }

    private get locationComponent(): Promise<LocationComponent> {
        return this.getComponent(LocationComponent).then(
            (c) => c as LocationComponent
        );
    }

    async load(): Promise<Array<ComponentError>> {
        await this._unityLoadCompleter.promise;
        await this._gameLoadCompleter.promise;

        this.addEventListener(this._defaultEventListener);

        await this.setDependencyLocked([
            LocationComponent,
            AuthenticationComponent,
        ]);
        const locationComponent = await this.locationComponent;
        const position = locationComponent.lastPosition;
        this._initialize(position!, ValueContainer.username!);

        await this.setDependencyLocked([SettingsComponent]);

        const settingsComponent = await ContainerHelper.getSettingsComponent();

        this.setFollowHeading(
            settingsComponent.getBoolFromLocalSettings(
                SettingsComponent.FOLLOW_HEADING_KEY,
                false
            )
        );

        const followLocation = settingsComponent.getBoolFromLocalSettings(
            SettingsComponent.FOLLOW_LOCATION_KEY,
            true
        );

        if (followLocation) {
            this.setCameraMode(UnityCameraMode.FOLLOW);
        } else {
            this.setCameraMode(UnityCameraMode.FREE);
        }

        await this.setTargetFrameRate(60);

        return [];
    }

    onReactMessage(message: string): void {
        if (message.includes('map:ready')) {
            this._gameLoadCompleter.resolve();
        }

        // decode to json
        const data = JSON.parse(message);
        // check if there is an "event" key
        if (data.event) {
            const event = data.event;
            const args: any[] = [];

            // arg0, arg1 etc
            while (data['arg' + args.length] !== undefined) {
                args.push(data['arg' + args.length]);
            }

            // call all listeners
            this._eventListeners.forEach((listener) => listener(event, args));
        } else if (data.requestId) {
            // check if there is a requestId key
            const completer = this._pendingRequests.get(data.requestId);
            if (completer) {
                const response = data.response;

                completer.resolve(response);
                this._pendingRequests.delete(data.requestId);
            }
        }
    }

    get name(): string {
        return 'Unity Component';
    }

    onPause(): Promise<void> {
        return Promise.resolve(undefined);
    }

    onResume(): Promise<void> {
        return Promise.resolve(undefined);
    }

    onUnload(): Promise<void> {
        this._eventListeners = this._eventListeners.filter(
            (listener) => listener !== this._defaultEventListener
        );
        return Promise.resolve(undefined);
    }

    update(): void {}

    get type(): Function {
        return UnityComponent;
    }

    postRequest<T>(request: string, parameters: string[]): Promise<T> {
        const completer = new Completer<T>();
        const _uuid = uuid();
        this._pendingRequests.set(_uuid, completer);
        this._sendMessage(
            'UnityMessageManager',
            'PostRequest',
            JSON.stringify({
                requestId: _uuid,
                request: request,
                parameters: parameters,
            })
        );
        return completer.promise;
    }

    private _initialize(position: GeolocationPosition, username: string): void {
        this._sendMessage(
            'UnityMessageManager',
            'Initialize',
            JSON.stringify({
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
                username: username,
            })
        );
    }

    setFollowHeading(followHeading: boolean): void {
        ContainerHelper.getSettingsComponent().then((settingsComponent) => {
            settingsComponent.setLocalSetting(
                SettingsComponent.FOLLOW_HEADING_KEY,
                followHeading
            );
        });
        this._sendMessage(
            'UnityMessageManager',
            'SetFollowHeading',
            followHeading.toString()
        );
    }

    setCameraMode(cameraMode: UnityCameraMode, focusTarget?: string) {
        if (cameraMode === UnityCameraMode.FOCUS && !focusTarget) {
            return;
        }

        ContainerHelper.getSettingsComponent().then((settingsComponent) => {
            settingsComponent.setLocalSetting(
                SettingsComponent.FOLLOW_LOCATION_KEY,
                cameraMode === UnityCameraMode.FOLLOW // will be false if cameraMode is not follow
            );
        });

        this._sendMessage(
            'UnityMessageManager',
            'SetCameraMode',
            JSON.stringify({
                mode: cameraMode,
                ...(focusTarget && { focusTarget: focusTarget }),
            })
        );
    }

    async setTargetFrameRate(targetFrameRate: number): Promise<void> {
        await this.postRequest('SetTargetFrameRate', [
            targetFrameRate.toString(),
        ]);
    }

    async generateCharacterPreview(): Promise<string> {
        return this.postRequest('GenerateCharacterPreview', []);
    }

    setSettings(settings: Map<string, any>): void {
        // make sure keys are lower camel case

        const settingsLowerCamelCase = Array.from(settings).map(
            ([key, value]) => [
                key.charAt(0).toLowerCase() + key.slice(1),
                value,
            ]
        );

        this._sendMessage(
            'UnityMessageManager',
            'SetSettings',
            JSON.stringify(Object.fromEntries(settingsLowerCamelCase))
        );
    }

    destroyGameObject(gameObjectName: string): void {
        this._sendMessage(
            'UnityMessageManager',
            'DestroyGameObject',
            gameObjectName
        );
    }

    postInterpolationTargetDestinationWithReachTime(
        gameObjectName: string,
        targetGameObjectName: string,
        reachTime: Date,
        options: {
            watchoutMode?: boolean;
            watchoutRadius?: number;
            eligibleWatchoutPointsAmountTarget?: number;
        } = {}
    ): void {
        const {
            watchoutMode = false,
            watchoutRadius = 0.0,
            eligibleWatchoutPointsAmountTarget = 400,
        } = options;

        this._sendMessage(
            'UnityMessageManager',
            'AddLatLngInterpolatorTargetDestinationWithReachTime',
            JSON.stringify({
                name: gameObjectName,
                targetName: targetGameObjectName,
                reachTime: reachTime.toISOString(),
                watchoutMode: watchoutMode,
                watchoutRadius: watchoutRadius,
                eligibleWatchoutPointsAmountTarget:
                    eligibleWatchoutPointsAmountTarget,
            })
        );
    }

    postInterpolationDestinationWithReachTime(
        gameObjectName: string,
        latitude: number,
        longitude: number,
        reachTime: Date,
        options: {
            watchoutMode?: boolean;
            watchoutRadius?: number;
            eligibleWatchoutPointsAmountTarget?: number;
        } = {}
    ): void {
        const {
            watchoutMode = false,
            watchoutRadius = 0.0,
            eligibleWatchoutPointsAmountTarget = 400,
        } = options;

        return this._sendMessage(
            'UnityMessageManager',
            'AddLatLngInterpolatorDestinationWithReachTime',
            JSON.stringify({
                name: gameObjectName,
                location: {
                    latitude: latitude,
                    longitude: longitude,
                },
                reachTime: reachTime.toISOString(),
                watchoutMode: watchoutMode,
                watchoutRadius: watchoutRadius,
                eligibleWatchoutPointsAmountTarget:
                    eligibleWatchoutPointsAmountTarget,
            })
        );
    }

    createDiamondBubble({
        name,
        latitude,
        longitude,
        isEvent,
        color,
        ringColor,
        rarity,
    }: {
        name: string;
        latitude: number;
        longitude: number;
        isEvent: boolean;
        color: Color;
        ringColor: Color;
        rarity: string;
    }): Promise<unknown> {
        const bubbleCreationObject = {
            name: name,
            latitude: latitude,
            longitude: longitude,
            isEvent: isEvent,
            color: {
                r: color.r,
                g: color.g,
                b: color.b,
            },
            ringColor: {
                r: ringColor.r,
                g: ringColor.g,
                b: ringColor.b,
            },
            rarity: rarity,
        };

        return this.postRequest('CreateDiamondBubble', [
            JSON.stringify(bubbleCreationObject),
        ]);
    }

    createStoneBubble({
        name,
        latitude,
        longitude,
        isEvent,
        color,
        ringColor,
        rarity,
    }: {
        name: string;
        latitude: number;
        longitude: number;
        isEvent: boolean;
        color: Color;
        ringColor: Color;
        rarity: string;
    }): Promise<unknown> {
        const bubbleCreationObject = {
            name,
            latitude,
            longitude,
            isEvent,
            color: {
                r: color.r,
                g: color.g,
                b: color.b,
            },
            ringColor: {
                r: ringColor.r,
                g: ringColor.g,
                b: ringColor.b,
            },
            rarity,
        };

        return this.postRequest('CreateStoneBubble', [
            JSON.stringify(bubbleCreationObject),
        ]);
    }

    createTurboBubble({
        name,
        latitude,
        longitude,
        isEvent,
        color,
        ringColor,
        rarity,
    }: {
        name: string;
        latitude: number;
        longitude: number;
        isEvent: boolean;
        color: Color;
        ringColor: Color;
        rarity: string;
    }): Promise<unknown> {
        const bubbleCreationObject = {
            name,
            latitude,
            longitude,
            isEvent,
            color: {
                r: color.r,
                g: color.g,
                b: color.b,
            },
            ringColor: {
                r: ringColor.r,
                g: ringColor.g,
                b: ringColor.b,
            },
            rarity,
        };

        return this.postRequest('CreateTurboBubble', [
            JSON.stringify(bubbleCreationObject),
        ]);
    }

    createCoinsBubble({
        name,
        latitude,
        longitude,
        isEvent,
        color,
        ringColor,
        rarity,
    }: {
        name: string;
        latitude: number;
        longitude: number;
        isEvent: boolean;
        color: Color;
        ringColor: Color;
        rarity: string;
    }): Promise<unknown> {
        const bubbleCreationObject = {
            name,
            latitude,
            longitude,
            isEvent,
            color: {
                r: color.r,
                g: color.g,
                b: color.b,
            },
            ringColor: {
                r: ringColor.r,
                g: ringColor.g,
                b: ringColor.b,
            },
            rarity,
        };

        return this.postRequest('CreateCoinsBubble', [
            JSON.stringify(bubbleCreationObject),
        ]);
    }

    createEvent({
        name,
        logoUrl,
        latitude,
        longitude,
        range,
        endTime,
        startTime,
        color,
    }: {
        name: string;
        logoUrl: string;
        latitude: number;
        longitude: number;
        range: number;
        endTime?: Date;
        startTime?: Date;
        color?: Color;
    }): void {
        const eventCreationObject = {
            name,
            logoUrl,
            logoUrls: null,
            latitude,
            longitude,
            range,
            endTimeTimestamp: endTime?.toISOString(),
            startTimeTimestamp: startTime?.toISOString(),
            activeText: 'active', // Replace with localized text as needed TODO: tr()
            endedText: 'ended', // Replace with localized text as needed TODO: tr()
            upcomingText: 'upcoming', // Replace with localized text as needed TODO: tr()
            ...(color && {
                color: {
                    r: color.r,
                    g: color.g,
                    b: color.b,
                },
            }),
        };

        this._sendMessage(
            'UnityMessageManager',
            'CreateEvent',
            JSON.stringify(eventCreationObject)
        );
    }

    createClusterProfile({
        name,
        logoUrls,
        latitude,
        longitude,
        range,
        color,
        preferredBackgroundColor,
    }: {
        name: string;
        logoUrls: string[];
        latitude: number;
        longitude: number;
        range: number;
        color?: Color;
        preferredBackgroundColor?: Color;
    }): void {
        const profileCreationObject = {
            name,
            logoUrl: null,
            logoUrls,
            latitude,
            longitude,
            range,
            ...(color && {
                color: {
                    r: color.r,
                    g: color.g,
                    b: color.b,
                },
            }),
            ...(preferredBackgroundColor && {
                preferredBackgroundColor: {
                    r: preferredBackgroundColor.r,
                    g: preferredBackgroundColor.g,
                    b: preferredBackgroundColor.b,
                },
            }),
        };

        this._sendMessage(
            'UnityMessageManager',
            'CreateProfile',
            JSON.stringify(profileCreationObject)
        );
    }

    createProfile({
        name,
        logoUrl,
        latitude,
        longitude,
        range,
        color,
        preferredBackgroundColor,
    }: {
        name: string;
        logoUrl: string;
        latitude: number;
        longitude: number;
        range: number;
        color?: Color;
        preferredBackgroundColor?: Color;
    }): void {
        const profileCreationObject = {
            name,
            logoUrl,
            logoUrls: null,
            latitude,
            longitude,
            range,
            ...(color && {
                color: {
                    r: color.r,
                    g: color.g,
                    b: color.b,
                },
            }),
            ...(preferredBackgroundColor && {
                preferredBackgroundColor: {
                    r: preferredBackgroundColor.r,
                    g: preferredBackgroundColor.g,
                    b: preferredBackgroundColor.b,
                },
            }),
        };

        this._sendMessage(
            'UnityMessageManager',
            'CreateProfile',
            JSON.stringify(profileCreationObject)
        );
    }

    postCharacterCreation(
        telegramId: string,
        latitude: number,
        longitude: number,
        characterBuildString: string
    ) {
        this._sendMessage(
            'UnityMessageManager',
            'CreateCharacter',
            JSON.stringify({
                username: telegramId, // TODO: Include telegramId and username
                location: {
                    latitude: latitude,
                    longitude: longitude,
                },
                characterBuildString: characterBuildString,
            })
        );
    }

    createPet({
        gameObjectNamePrefix,
        addressableAssetName,
        displayName,
        latitude,
        longitude,
    }: {
        gameObjectNamePrefix: string;
        addressableAssetName: string;
        displayName: string;
        latitude: number;
        longitude: number;
    }): Promise<void> {
        const animalCreationObject = {
            gameObjectNamePrefix: gameObjectNamePrefix,
            addressableAssetName: addressableAssetName,
            displayName: displayName,
            location: {
                latitude,
                longitude,
            },
        };

        return this.postRequest('CreatePet', [
            JSON.stringify(animalCreationObject),
        ]);
    }

    async getObjectScreenPosition(
        gameObject: string,
        position: string,
        offset: number
    ): Promise<[number, number]> {
        const response = await this.postRequest<any>(
            'GetObjectScreenPosition',
            [gameObject, position, offset.toString()]
        );
        return [response.x, response.y];
    }

    getCameraBearing(): Promise<number> {
        return this.postRequest<number>('GetCameraBearing', []);
    }

    setCharacterPathLineTargetPosition(
        lat: number,
        lng: number
    ): Promise<void> {
        return this.postRequest('SetCharacterPathLineTargetPosition', [
            lat.toString(),
            lng.toString(),
        ]);
    }

    setCharacter(characterBuildString: string): void {
        this._sendMessage(
            'UnityMessageManager',
            'SetCharacterModel',
            JSON.stringify({
                isMale: true,
                index: 0,
                characterBuildString: characterBuildString,
            })
        );
    }
}

export default UnityComponent;
