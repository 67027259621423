import TaskPlatform from "./task_platform";

class Task {
    taskId: string;
    userId: string;
    taskType: string;
    title: string;
    description: string;
    points: number;
    tokens: number;
    url: string | null;
    startedAt: Date | null;
    completedAt: Date | null;
    estCompletionAt: Date | null;
    status: string;
    pointsEarned: number | null;
    tokensEarned: number | null;
    tokenName: string | null;
    tokenImage: string | null;
    verifiable: boolean;
    verified: boolean;
    partnerTask: boolean;
    partnerName: string;
    platform: TaskPlatform;

    constructor({
        taskId,
        userId,
        taskType,
        title,
        description,
        points, 
        tokens,
        url,
        startedAt,
        completedAt,
        estCompletionAt,
        status,
        pointsEarned,
        tokensEarned,
        tokenName,
        tokenImage,
        verifiable,
        verified,
        partnerTask,
        partnerName,
        platform    
    }: {
        taskId: string;
        userId: string;
        taskType: string;
        title: string;
        description: string;
        points: number;
        tokens: number;
        url: string | null;
        startedAt: Date | null;
        completedAt: Date | null;
        estCompletionAt: Date | null;
        status: string;
        pointsEarned: number | null;
        tokensEarned: number | null;
        tokenName: string | null;
        tokenImage: string | null;
        verifiable: boolean;
        verified: boolean;
        partnerTask: boolean;
        partnerName: string;
        platform: TaskPlatform;
    }) {
        this.taskId = taskId;
        this.userId = userId;
        this.taskType = taskType;
        this.title = title;
        this.description = description;
        this.points = points;
        this.tokens = tokens;
        this.url = url;
        this.startedAt = startedAt ? new Date(startedAt) : null;
        this.completedAt = completedAt ? new Date(completedAt) : null;
        this.estCompletionAt = estCompletionAt ? new Date(estCompletionAt) : null;
        this.status = status;
        this.pointsEarned = pointsEarned;
        this.tokensEarned = tokensEarned;
        this.tokenName = tokenName;
        this.tokenImage = tokenImage
        this.verifiable = verifiable
        this.verified = verified;
        this.partnerTask = partnerTask;
        this.partnerName = partnerName;
        this.platform = platform;
    }

    static fromJsonLowerCamelCase(json: any): Task {
        return new Task({
            taskId: json.taskId,
            userId: json.userId,
            taskType: json.taskType,
            title: json.title,
            description: json.description,
            points: json.points,
            tokens: json.tokens,
            url: json.url,
            startedAt: json.startedAt ? new Date(json.startedAt) : null,
            completedAt: json.completedAt ? new Date(json.completedAt) : null,
            estCompletionAt: json.estCompletionAt ? new Date(json.estCompletionAt) : null,
            status: json.status,
            pointsEarned: json.pointsEarned,
            tokensEarned: json.tokensEarned,
            tokenName: json.tokenName,
            tokenImage: json.tokenImage,
            verifiable: json.verifiable,
            verified: json.verified,
            partnerTask: json.partnerTask,
            partnerName: json.partnerName,
            platform: json.platform
        });
    }

    static fromJsonUpperCase(json: any): Task {
        return new Task({
            taskId: json.TaskId,
            userId: json.UserId,
            taskType: json.TaskType,
            title: json.Title,
            description: json.Description,
            points: json.Points,
            tokens: json.Tokens,
            url: json.Url,
            startedAt: json.StartedAt ? new Date(json.StartedAt) : null,
            completedAt: json.CompletedAt ? new Date(json.CompletedAt) : null,
            estCompletionAt: json.EstCompletionAt ? new Date(json.EstCompletionAt) : null,
            status: json.Status,
            pointsEarned: json.PointsEarned,
            tokensEarned: json.TokensEarned,
            tokenName: json.TokenName,
            tokenImage: json.TokenImage,
            verifiable: json.Verifiable,
            verified: json.Verified,
            partnerTask: json.PartnerTask,
            partnerName: json.PartnerName,
            platform: json.Platform
        });
    }
}

export default Task;