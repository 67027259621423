class OfferActivationCost {
    cryptoCurrency: string;
    cryptoCurrencyAmount: number;

    constructor(cryptoCurrency: string, cryptoCurrencyAmount: number) {
        this.cryptoCurrency = cryptoCurrency;
        this.cryptoCurrencyAmount = cryptoCurrencyAmount;
    }

    static fromJsonLowerCamelCase(json: {
        [key: string]: any;
    }): OfferActivationCost {
        return new OfferActivationCost(
            json['cryptoCurrency'],
            json['cryptoCurrencyAmount']
        );
    }

    static fromJsonUpperCamelCase(json: {
        [key: string]: any;
    }): OfferActivationCost {
        return new OfferActivationCost(
            json['CryptoCurrency'],
            json['CryptoCurrencyAmount']
        );
    }
}

export default OfferActivationCost;
