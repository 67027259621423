class PlayerEffect {
    effectName: string;
    expirationDate: Date;
    stackCount: number;
    value: number;

    constructor(
        effectName: string,
        expirationDate: string,
        stackCount: number,
        value: number
    ) {
        this.effectName = effectName;
        this.expirationDate = new Date(expirationDate);
        this.stackCount = stackCount;
        this.value = value;
    }

    static fromJsonLowerCamelCase(json: any): PlayerEffect {
        return new PlayerEffect(
            json.effectName,
            json.expirationDate,
            json.stackCount,
            json.value
        );
    }

    static fromJsonUpperCamelCase(json: any): PlayerEffect {
        return new PlayerEffect(
            json.EffectName,
            json.ExpirationDate,
            json.StackCount,
            json.Value
        );
    }
}

export default PlayerEffect;
