import React, { useCallback, useEffect, useRef, useState } from 'react';
import styles from './loot_box_spinner.module.css';
import CompactJsonOffer from '../../../apis/offer_api/compact_json_offer';
import { Item, ItemRarity } from '../../../apis/customization_api/item';
import {
    constants,
    rarity_gradient,
    rarity_shadow,
} from '../../../utils/constants';
import SpinnerUtility from './spinner_utility';
import { throttle } from 'lodash';
import AcquiredOffer from '../../../apis/offer_api/acquired_offer';

const ITEM_IMAGE_URL_STRUCTURE = constants.ITEM_IMAGE_URL_STRUCTURE;

interface LootBoxSpinnerProps {
    allItems: Array<Item | CompactJsonOffer>;
    winning: Item | AcquiredOffer;
    onSpinComplete: () => void;
}

const getRarityColor = (rarity: ItemRarity) => {
    switch (rarity) {
        case ItemRarity.Common:
            return rarity_gradient.Common;
        case ItemRarity.Rare:
            return rarity_gradient.Rare;
        case ItemRarity.Epic:
            return rarity_gradient.Epic;
        case ItemRarity.Legendary:
            return rarity_gradient.Legendary;
        default:
            return rarity_gradient.Common;
    }
};

const getRarityShadow = (rarity: ItemRarity) => {
    switch (rarity) {
        case ItemRarity.Common:
            return rarity_shadow.Common;
        case ItemRarity.Rare:
            return rarity_shadow.Rare;
        case ItemRarity.Epic:
            return rarity_shadow.Epic;
        case ItemRarity.Legendary:
            return rarity_shadow.Legendary;
        default:
            return rarity_shadow.Common;
    }
};

const getRarity = (rarity: string | ItemRarity | undefined): ItemRarity => {
    if (!rarity) {
        return ItemRarity.Common;
    }

    // Check if it's already an ItemRarity enum
    if (typeof rarity === 'number') {
        return rarity;
    }

    rarity = rarity.charAt(0).toUpperCase() + rarity.slice(1).toLowerCase();
    switch (rarity) {
        case 'Common':
            return ItemRarity.Common;
        case 'Rare':
            return ItemRarity.Rare;
        case 'Epic':
            return ItemRarity.Epic;
        case 'Legendary':
            return ItemRarity.Legendary;
        default:
            return ItemRarity.Common;
    }
};

const ITEM_WIDTH = 100; // Adjust based on your item card width in pixels
const SPIN_DURATION = 14000; // Duration of the spin in milliseconds
const PIXELS_PER_SECOND = 7000; // Speed of the spin in pixels per second

const LootBoxSpinner: React.FC<LootBoxSpinnerProps> = ({
    allItems,
    winning,
    onSpinComplete,
}) => {
    const [isSpinning, setIsSpinning] = useState(false);
    const [, setRenderFlag] = useState(0);

    const getId = useCallback(
        (item: Item | CompactJsonOffer | AcquiredOffer) => {
            if ('offerId' in item) {
                return item.offerId; // CompactJsonOffer case
            } else if ('id' in item) {
                return item.id; // Item case
            } else if ('nonUniqueId' in item) {
                return item.nonUniqueId; // AcquiredOffer case
            }

            // Fallback for unexpected cases
            throw new Error('Item does not have a valid identifier');
        },
        []
    );

    const spinnerUtility = useRef<SpinnerUtility | null>();

    // useEffect to create a new spinner utility
    useEffect(() => {
        spinnerUtility.current = new SpinnerUtility({
            itemHeight: 0,
            itemWidth: ITEM_WIDTH,
            numberOfItems: allItems.length,
            pixelsPerSecond: PIXELS_PER_SECOND,
            runTime: SPIN_DURATION,
            screenHeight: window.innerHeight,
            screenWidth: window.innerWidth,
            spacing: 10,
            winIndex: allItems.findIndex(
                (item) => getId(item) === getId(winning)
            ),
            onSpinComplete: () => {
                // setIsSpinning(false);
                onSpinComplete();
            },
        });
        setRenderFlag((flag) => flag + 1);
    }, []);

    const throttledUpdate = useCallback(
        throttle((time: number) => {
            spinnerUtility.current!.update(undefined);
            setRenderFlag((flag) => flag + 1);
        }, 16), // Throttle updates to ~60 FPS
        []
    );

    useEffect(() => {
        const update = (time: number) => {
            if (isSpinning) {
                throttledUpdate(time);
                requestAnimationFrame(update);
            }
        };

        requestAnimationFrame(update);
    }, [isSpinning, throttledUpdate]);

    const startSpin = () => {
        spinnerUtility.current!.start();
        setIsSpinning(true);
    };

    const getItemImageSrc = (item: Item | CompactJsonOffer) => {
        if ('imagePath' in item) {
            return ITEM_IMAGE_URL_STRUCTURE.replace('%ITEM_ID%', item.id!);
        } else {
            return (
                (item as CompactJsonOffer).coverUrl ||
                (item as CompactJsonOffer).url
            );
        }
    };

    if (!spinnerUtility.current) {
        return null;
    }

    return (
        <div className={styles.spinnerContainer}>
            <div
                className={styles.spinner}
                style={{
                    display: 'flex',
                    overflowX: 'visible',
                    overflowY: 'visible',
                    whiteSpace: 'nowrap',
                    height: '150px',
                }}
            >
                {spinnerUtility.current.items.map((spinnerItem, index) => {
                    const item = allItems[spinnerItem.itemIndex];
                    const selected =
                        spinnerUtility.current!.currentlySelectedUniqueIndex ===
                        spinnerItem.uniqueIndex;

                    return (
                        <div
                            key={`${getId(item)}-${index}`}
                            data-selected={selected ? 'true' : 'false'}
                            className={styles.itemCard}
                            style={{
                                width: `${ITEM_WIDTH}px`,
                                // height: `150px`,
                                transition: 'transform 0.5s ease-in-out',
                                // transform: `translateX(${spinnerUtility.current.items[index].x}px)`,
                                position: 'absolute',
                                left: `${spinnerUtility.current!.items[index].x}px`,
                                // top: `${spinnerUtility.current.items[index].y}px`,

                                background: getRarityColor(
                                    getRarity(item.rarity)
                                ),
                                //      Shiny shadow based on rarity
                                boxShadow: getRarityShadow(
                                    getRarity(item.rarity)
                                ),
                            }}
                        >
                            <img
                                src={getItemImageSrc(item)}
                                alt={item.name}
                                className={styles.itemImage}
                            />
                            <div className={styles.itemInfo}>
                                <h3>{item.name}</h3>
                            </div>
                        </div>
                    );
                })}
                <div
                    className={styles.lineSelector}
                    style={{
                        left: `${window.innerWidth / 2}px`,
                        height: `150px`,
                        //     Golden shadow
                        boxShadow: `0 0 7px 7px rgba(255, 215, 0, 0.5)`,
                    }}
                ></div>
            </div>

            <button
                className={styles.spinButton}
                onClick={startSpin}
                disabled={isSpinning}
                style={{
                    background: isSpinning
                        ? 'gray'
                        : getRarityColor(ItemRarity.Legendary),
                    boxShadow: isSpinning
                        ? 'none'
                        : getRarityShadow(ItemRarity.Legendary),
                }}
            >
                Spin
            </button>
        </div>
    );
};

export default LootBoxSpinner;
