interface IconFadeOutProps {
    icon: string;
    alt?: string;
    iconSize?: number;
}

const IconFadeOut: React.FC<IconFadeOutProps> = ({
    icon,
    alt = 'icon',
    iconSize = 24,
}) => {
    return (
        <img
            src={icon}
            alt={alt}
            style={{
                width: `${iconSize}px`,
                height: `${iconSize}px`,
            }}
        />
    );
};

export default IconFadeOut;
