import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import ContainerHelper from '../../component_system/component_container/utilities/container_helper';
import styles from './map_overlay.module.css';

import UnityCameraMode from '../../component_system/components/unity/unity_camera_mode';
import TopPanelCounter from '../components/overlay/top_panel_counter';

import Images from '../../component_system/components/preload/images';
import useFetchComponent from '../../component_system/component_container/utilities/use_fetch_hook';
import useObservable from '../../utils/observable/use_observable_hook';
import ValueContainer from '../../utils/value_container';
import LocationComponent from '../../component_system/components/location/location_component';
import { millisecondsOf } from 'typed-duration/dist/lib';
import MapBubble from '../../apis/models/map_bubble';
import DigWindow from '../components/dig/dig_window';
import { toast } from 'react-toastify';
import MiniProfile from '../components/overlay/mini_profile';
import EffectIconWithCountdown from '../components/overlay/effect_icon_with_countdown';
import AcquiredOffer from '../../apis/offer_api/acquired_offer';

const MapOverlay = () => {
    const [navigationBarHeight, setNavigationBarHeight] = useState(0);
    const [topPanelRef, setTopPanelRef] = useState<HTMLDivElement | null>(null);
    const [topPanelHeight, setTopPanelHeight] = useState(0);
    const [topPanelWidth, setTopPanelWidth] = useState(0);
    const [cameraMode, setCameraMode] = useState(UnityCameraMode.FOLLOW);
    const [
        cameraModeChangedFromFocusToFree,
        setCameraModeChangedFromFocusToFree,
    ] = useState(false);
    const [joystickMode, setJoystickMode] = useState(true);
    const [focusedBubble, setFocusedBubble] = useState<MapBubble | undefined>(
        undefined
    );
    const [bubblePopupOpen, setBubblePopupOpen] = useState(false);

    const topPanelClickedTimes = useRef(0);

    const isDebug = useObservable(ValueContainer.isDebugObservable);

    const locationComponent = useFetchComponent(
        ContainerHelper.getLocationComponent
    );
    const overlayComponent = useFetchComponent(
        ContainerHelper.getOverlayComponent
    );
    const navigationComponent = useFetchComponent(
        ContainerHelper.getNavigationComponent
    );
    const unityComponent = useFetchComponent(ContainerHelper.getUnityComponent);
    const populationComponent = useFetchComponent(
        ContainerHelper.getPopulationComponent
    );
    const settingsComponent = useFetchComponent(
        ContainerHelper.getSettingsComponent
    );

    useEffect(() => {
        if (!overlayComponent) {
            return;
        }

        const bubbleTouchListener = (bubble: MapBubble) => {
            setFocusedBubble(bubble);
            setBubblePopupOpen(true);
        };

        overlayComponent.addOnBubbleTouchListener(bubbleTouchListener);

        return () => {
            overlayComponent.removeOnBubbleTouchListener(bubbleTouchListener);
        };
    }, [overlayComponent]);

    useEffect(() => {
        if (!overlayComponent) {
            return;
        }

        if (overlayComponent.focusedBubble === undefined && focusedBubble) {
            setFocusedBubble(overlayComponent.focusedBubble);
            setBubblePopupOpen(false);
        }
    });

    useEffect(() => {
        if (!navigationComponent) {
            return;
        }

        navigationComponent.visibilityPreference = !bubblePopupOpen;
    }, [bubblePopupOpen, navigationComponent]);

    const onDigWindowClose = useCallback(() => {
        setBubblePopupOpen(false);
    }, []);

    const onDigWindowShowInVault = useCallback(
        (acquiredOffer: AcquiredOffer) => {
            console.error('Show in vault');
            setBubblePopupOpen(false);
            navigationComponent?.navigateToIndex(1, {
                acquiredOffer,
            });
            unityComponent!.setCameraMode(UnityCameraMode.FOLLOW);
        },
        [navigationComponent, unityComponent]
    );

    const onJoystickButtonClick = useCallback(() => {
        setJoystickMode(!joystickMode);
        locationComponent?.setUseDebugPositions(!joystickMode);
    }, [joystickMode, locationComponent]);

    const onTopPanelClick = useCallback(() => {
        topPanelClickedTimes.current++;

        if (topPanelClickedTimes.current >= 5) {
            topPanelClickedTimes.current = 0;

            if (ValueContainer.userType !== 1) {
                toast.error('You are not an admin');
                return;
            }

            ValueContainer.isDebug = !isDebug;

            ContainerHelper.getOverlayComponent().then((component) => {
                component.addElement(
                    'Character',
                    <p
                        style={{
                            margin: 0,
                            padding: 0,
                            textAlign: 'center',
                            color: 'white',
                        }}
                    >
                        Debug mode is now {!isDebug ? 'enabled' : 'disabled'}
                    </p>,
                    2000,
                    1
                );
            });
        }
    }, [isDebug]);

    const [overlayElements, setOverlayElements] = useState<React.JSX.Element[]>(
        []
    );

    useEffect(() => {
        if (topPanelRef) {
            setTopPanelHeight(topPanelRef.clientHeight);
            setTopPanelWidth(topPanelRef.clientWidth);
        }
    }, [topPanelRef]);

    const cameraModeSubscription = useCallback(
        (fromMode: UnityCameraMode, mode: UnityCameraMode) => {
            setCameraMode(mode);

            if (
                fromMode === UnityCameraMode.FOCUS &&
                mode === UnityCameraMode.FREE
            ) {
                setCameraModeChangedFromFocusToFree(true);
                console.error('Camera mode changed from focus to free');
            } else {
                setCameraModeChangedFromFocusToFree(false);
            }

            // Print from
        },
        []
    );

    useEffect(() => {
        const overlayComponentSubscriber = (elements: React.JSX.Element[]) => {
            setOverlayElements(elements);
        };

        overlayComponentSubscriber(overlayComponent?.elements ?? []);

        overlayComponent?.addSubscriber(overlayComponentSubscriber);

        return () => {
            overlayComponent?.removeSubscriber(overlayComponentSubscriber);
        };
    }, [overlayComponent]);

    useEffect(() => {
        ContainerHelper.getNavigationComponent().then((component) => {
            setNavigationBarHeight(component.navigationBarHeight);
        });

        ContainerHelper.getUnityComponent().then((component) => {
            setCameraMode(component.cameraMode);

            component.addCameraModeSubscription(cameraModeSubscription);
        });

        return () => {
            unityComponent?.removeCameraModeSubscription(
                cameraModeSubscription
            );
        };
    }, []);

    const topPanel = useMemo(() => {
        return (
            <TopPanelCounter
                onRef={setTopPanelRef}
                containerClassName={styles.top_panel_overlay}
                onTopPanelClick={onTopPanelClick}
            />
        );
    }, [onTopPanelClick]);

    // const levelProgressBar = useMemo(() => {
    //     return (
    //         <div
    //             style={{
    //                 position: 'absolute',
    //                 top: topPanelHeight * 1.37 + 5,
    //                 left: 'var(--overlay-margin-horizontal)',
    //                 // shadow
    //                 boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.75)',
    //                 borderRadius: (0.37 * topPanelHeight) / 2,
    //             }}
    //         >
    //             <LevelProgressBar
    //                 width={topPanelHeight * 1.37}
    //                 height={0.15 * topPanelHeight}
    //             />
    //         </div>
    //     );
    // }, [topPanelHeight]);

    const joystickButton = useMemo(() => {
        if (!isDebug) {
            return null;
        }

        // noinspection JSSuspiciousNameCombination
        return (
            <div
                className={`${styles.pinButton} ${styles.star} ${cameraMode === UnityCameraMode.FOLLOW ? styles.active : styles.inactive}`}
                onClick={onJoystickButtonClick}
            >
                <img src={Images.Icons.Star} alt="" />
            </div>
        );
    }, [isDebug, joystickMode, onJoystickButtonClick, topPanelHeight]);

    const cameraModeButton = useMemo(() => {
        // noinspection JSSuspiciousNameCombination
        return (
            <div
                className={`${styles.pinButton} ${cameraMode === UnityCameraMode.FOLLOW ? styles.active : styles.inactive}`}
                onClick={() => {
                    unityComponent?.setCameraMode(
                        cameraMode === UnityCameraMode.FOLLOW
                            ? UnityCameraMode.FREE
                            : UnityCameraMode.FOLLOW
                    );
                }}
            >
                <img src={Images.Icons.Location} alt="" />
            </div>
        );
    }, [cameraMode, topPanelHeight, unityComponent]);

    const onJoystickControlClick = useCallback(
        async (bearing: number) => {
            const cameraBearing = await unityComponent!.getCameraBearing();
            let newBearing = cameraBearing + bearing;

            if (newBearing < 0) {
                newBearing += 360;
            } else if (newBearing >= 360) {
                newBearing -= 360;
            }

            const newTargetPosition =
                LocationComponent.calculatePositionFromDistanceAndBearing(
                    locationComponent!.targetPosition ||
                        locationComponent!.lastPosition!,
                    10,
                    newBearing
                );

            const maxPathLineDistance =
                settingsComponent!.getDoubleFromClientSettings(
                    'MaxPathLineDistance',
                    10.0
                );

            if (
                LocationComponent.getDistanceBetweenPositions(
                    locationComponent!.lastPosition!,
                    newTargetPosition
                ) > maxPathLineDistance
            ) {
                return;
            }

            locationComponent!.targetPosition = newTargetPosition;
        },
        [unityComponent, locationComponent]
    );

    const joystickControls = useMemo(() => {
        if (!joystickMode) {
            return null;
        }

        return (
            <div className={styles.joystick_controls}>
                <button
                    className={styles.joystick_control_button}
                    onClick={() => onJoystickControlClick(0)}
                >
                    <img
                        src={Images.Icons.ArrowBack}
                        alt="Arrow Up"
                        style={{
                            width: 24,
                            height: 24,
                            margin: 0,
                            transform: 'rotate(90deg)',
                        }}
                    />
                </button>
                <button
                    className={styles.joystick_control_button}
                    onClick={() => onJoystickControlClick(270)}
                >
                    <img
                        src={Images.Icons.ArrowBack}
                        alt="Arrow Left"
                        style={{
                            width: 24,
                            height: 24,
                            margin: 0,
                        }}
                    />
                </button>
                <button
                    className={styles.joystick_control_button}
                    onClick={() => onJoystickControlClick(90)}
                >
                    <img
                        src={Images.Icons.ArrowBack}
                        alt="Arrow Right"
                        style={{
                            width: 24,
                            height: 24,
                            margin: 0,
                            transform: 'rotate(180deg)',
                        }}
                    />
                </button>
                <button
                    className={styles.joystick_control_button}
                    onClick={() => onJoystickControlClick(180)}
                >
                    <img
                        src={Images.Icons.ArrowBack}
                        alt="Arrow Down"
                        style={{
                            width: 24,
                            height: 24,
                            margin: 0,
                            transform: 'rotate(270deg)',
                        }}
                    />
                </button>
            </div>
        );
    }, [joystickMode, onJoystickControlClick]);

    const recenterButton = useMemo(() => {
        if (cameraMode !== UnityCameraMode.FREE) {
            return null;
        }

        return (
            <button
                className={styles.recenter_button}
                onClick={() => {
                    unityComponent?.setCameraMode(UnityCameraMode.FOLLOW);
                }}
                style={{
                    height: topPanelHeight / 2,
                }}
            >
                <p className={styles.recenter_button_text}>{'recenter'.tr()}</p>
            </button>
        );
    }, [cameraMode, topPanelHeight, unityComponent]);

    const onUserAvatarClick = useCallback(() => {
        navigationComponent?.navigateToIndex(4);
    }, [navigationComponent]);

    const userAvatar = useMemo(() => {
        return (
            <MiniProfile
                onProfileAction={onUserAvatarClick}
                onCoinsAction={onTopPanelClick}
                onRef={setTopPanelRef}
            />
        );
    }, [onUserAvatarClick, topPanelHeight]);

    return (
        <div
            className={styles.overlay}
            style={{
                height: `calc(100% - ${navigationBarHeight}px)`,
            }}
        >
            {overlayElements}
            {/*   {topPanel} */}
            {cameraModeButton}
            {joystickButton}
            {joystickControls}
            {recenterButton}
            {userAvatar}
            {bubblePopupOpen && cameraModeChangedFromFocusToFree && (
                <DigWindow
                    onClose={onDigWindowClose}
                    onView={onDigWindowShowInVault}
                />
            )}
            {/*{levelProgressBar}*/}
            {populationComponent?.playerEffects?.find(
                (pe) => pe.effectName === 'Speed'
            ) && (
                <div
                    style={{
                        position: 'absolute',
                        top: 80,
                        right: 10,
                        zIndex: 2,
                    }} // TODO: Move style to css
                >
                    <EffectIconWithCountdown
                        icon={Images.Icons.SpeedBoost}
                        iconSize={34}
                        expirationTime={
                            populationComponent!.playerEffects!.find(
                                (pe) => pe.effectName === 'Speed'
                            )!.expirationDate
                        }
                    />
                </div>
            )}
        </div>
    );
};

export default MapOverlay;
