import styles from './quests_page.module.css';
import QuestItem from '../components/quests/quest_item';
import { constants } from '../../utils/constants';
import {
    init,
    openLink,
    openTelegramLink,
    shareURL,
} from '@telegram-apps/sdk-react';
import ValueContainer from '../../utils/value_container';
import useFetchComponent from '../../component_system/component_container/utilities/use_fetch_hook';
import ContainerHelper from '../../component_system/component_container/utilities/container_helper';
import Images from '../../component_system/components/preload/images';
import React, { useEffect, useRef, useState } from 'react';
import QuestComponent from '../../component_system/components/quest/quest_component';
import Task from '../../apis/models/task/task';
import TaskStatus from '../../apis/models/task/task_status';
import TaskType from '../../apis/models/task/task_type';
import { toast } from 'react-toastify';
import { link } from 'fs';
import formatNumber from '../../utils/currency_formatter';
import GroupedTasks from '../../apis/models/task/task_group';
import QuestPartner from '../components/quests/quest_partner';

const REFER_URL_STRUCTURE = constants.REFER_URL_STRUCTURE;

const QuestsPage = () => {
    const navigationComponent = useFetchComponent(
        ContainerHelper.getNavigationComponent
    );
    const [groupedTasks, setGroupedTasks] = useState<GroupedTasks>({});
    const [referralCount, setReferralCount] = useState<number>(0);
    const [referralTokensEarned, setReferralTokensEarned] = useState<number>(0);
    const questComponentRef = useRef<QuestComponent | null>(null);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const subscriber = () => {
            // Update tasks when notified
            if (questComponentRef.current) {
                setGroupedTasks({ ...questComponentRef.current.groupedTasks });
                setReferralCount(questComponentRef.current.referralsCount);
                setReferralTokensEarned(
                    questComponentRef.current.referralTokensEarned
                );
            }
        };

        const setup = async () => {
            questComponentRef.current =
                await ContainerHelper.getQuestComponent();
            // Subscribe to updates
            questComponentRef.current.addSubscriber(subscriber);
            // Set initial tasks
            setGroupedTasks({ ...questComponentRef.current.groupedTasks });
            setReferralCount(questComponentRef.current.referralsCount);
            setReferralTokensEarned(
                questComponentRef.current.referralTokensEarned
            );
        };

        setup();

        // Cleanup on unmount
        return () => {
            if (questComponentRef.current) {
                questComponentRef.current.removeSubscriber(subscriber);
            }
        };
    }, []);

    useEffect(() => {
        const checkMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        setIsMobile(checkMobile);
    }, []);

   
    const customSafeArea = 120;

    const API_URL = constants.INVITE_URL;
    const inviteFriends = () => {
        const inviteLink = REFER_URL_STRUCTURE.replace(
            '%TID%',
            ValueContainer.telegramId
        );
        if (window.Telegram !== undefined)
            shareURL(inviteLink, 'Join DIGGERS GO and earn rewards!');
    };

    const handleCopyLink = () => {
        const inviteLink = REFER_URL_STRUCTURE.replace(
            '%TID%',
            ValueContainer.telegramId
        );
        navigator.clipboard.writeText(inviteLink);
        toast.info('Invite link copied to clipboard!');
    };

    const handleTelegramLink = (task: Task) => {
        if (openTelegramLink.isAvailable()) {
            openTelegramLink(task.url as string);
        }
    };

    const getDeepLink = (url: string): string => {
        if (url.includes('x.com')) {
            const username = url.split('.com/')[1]; // Extract the username
            return `twitter://user?screen_name=${username}`;
        }
        return url; // Default to the original URL
    };

    const openUrl = (task: Task) => {
        var link = task.url as string;
        if(isMobile){
            link = getDeepLink(link);
        }        
        window.open(link, '_blank');        
    };

    const handleTaskAction = async (task: Task) => {
        if (questComponentRef.current) {
            if (task.status === TaskStatus.NotStarted) {
                if (task.taskType === TaskType.Follow) {
                    openUrl(task);
                    if(task.verifiable){
                        const success = await questComponentRef.current.startTask(
                            task.taskId
                        );
                    } else {
                        const success = await questComponentRef.current.verifyTask(
                            task.taskId
                        );
                    }
                } else if (task.taskType === TaskType.Join) {
                    if(task.verifiable){
                        const success = await questComponentRef.current.startTask(
                            task.taskId
                        );
                    } else {
                        const success = await questComponentRef.current.verifyTask(
                            task.taskId
                        );
                    }
                  
                    handleTelegramLink(task);
                }
            } else if (task.status === TaskStatus.Started) {
                if (!task.verifiable) {
                       toast.info(`${calculateMinutesLeft(task.estCompletionAt)} minutes left until your task is verified!`);
                }
                else {
                    const success = await questComponentRef.current.verifyTask(
                        task.taskId
                    );
                }
            } else if (task.status === TaskStatus.Completed) {
                return;
            }
        }
    };

    const calculateMinutesLeft = (estimatedCompletionTimeUTC:  Date|null): number =>{
        // Convert the input to a Date object
        if (!estimatedCompletionTimeUTC) {
            return 0;
        }
        const completionTime = new Date(estimatedCompletionTimeUTC);
        const currentTime = new Date(); // Current time in UTC
    
        // Calculate the difference in milliseconds
        const timeDifference = completionTime.getTime() - currentTime.getTime();
    
        // Convert milliseconds to minutes
        const minutesLeft = Math.floor(timeDifference / 1000 / 60);
    
        // Ensure the result is not negative
        return Math.max(0, minutesLeft);
    }

    return (
        <>
            <div className={styles.pageBg}>
                <img
                    src={Images.Backgrounds.AnimatedBackground}
                    className={styles.animatedImage}
                    alt=""
                />
            </div>
            {/*
            <div className={styles.quests_header}>
                <div
                    className={styles.back_button}
                    onClick={() => navigationComponent?.goBack()}
                >
                    <img src={Images.Icons.ArrowBack} alt="Back" />
                </div>
                <div className={styles.quests_title}>{'quests'.tr()}</div>
            </div>
            */}

            <div className={styles.pageContent}>
                <h2 className={styles.pageTitle}>Quests</h2>

                <div
                    className={styles.referralPanel}
                    style={{ backgroundImage: `url(${Images.Panels.ReferralBanner})` }}
                >
                    <div className={styles.buttonContainer}>
                        <button
                            type="button"
                            onClick={inviteFriends}
                            className={styles.refBtn}
                            name="button"
                        >
                            Invite
                        </button>
                        <button
                            type="button"
                            className={`${styles.refBtn} ${styles.last}`}
                            name="button"
                            onClick={handleCopyLink}
                        >
                            Copy link
                        </button>
                    </div>                   
                </div>
                <div className={styles.referralStats}>     
                        
                        <div className={`${styles.referralStat} ${styles.referralStatFirst}`}>
                            <img className={styles.statIcon} src={Images.Icons.IconRef} alt="" />
                            <p>
                                <span>Referrals</span>
                                <br />
                                {`${referralCount}`}
                            </p>                           
                        </div>
                        <div className={styles.referralStat}>
                            <img className={styles.statIcon} src={Images.Icons.DiggersGoCoin} alt="" />
                            <p>
                            <span>Earnings</span>
                            <br />
                            {`${formatNumber(referralTokensEarned)}`} 
                            </p>
                        </div>
                </div>

                <div className={styles.divider}></div>
                <p className={styles.label}>DiggersGo Quests</p>



                 {groupedTasks["false"] && groupedTasks["false"]["None"].map((quest: Task, index) => (                                      
                        <QuestItem key={index}
                            partnerName="None"
                            task={quest}
                            action={() => handleTaskAction(quest)}
                        />

                ))}
                  {groupedTasks["true"] && Object.entries(groupedTasks["true"]).map(([partnerName, tasks]) => (
                    
                    <QuestPartner key={partnerName}
                        partnerName={partnerName}
                        tasks={tasks}
                        handleTaskAction={handleTaskAction}
                    />
                    
                ))}
                 

                 
                 <div style={{ height: customSafeArea }} />
            </div>
        </>
    );
};

export default QuestsPage;
