import Component from '../../component_container/models/component';
import ComponentError from '../../component_container/models/component_error';
import { TimeDuration } from 'typed-duration';
import TelegramApi from '../../../apis/telegram_api';
import TelegramValidationResult from './telegram_vaildation_result';
import ComponentErrorType from '../../component_container/enums/component_error_type';
import { search_params_keys } from '../../../utils/constants';
import UsernameValidationResult from './username_validation_result';
import ContainerHelper from '../../component_container/utilities/container_helper';
import { init } from '@telegram-apps/sdk-react';

class TelegramComponent extends Component {
    private _usernameCreationParameter?: string;
    private _authToken?: string;
    private _isNewUser?: boolean;

    get authToken(): string | undefined {
        return this._authToken;
    }

    get isNewUser(): boolean | undefined {
        return this._isNewUser;
    }

    set usernameCreationParameter(value: string | undefined) {
        this._usernameCreationParameter = value;
    }

    async load(): Promise<Array<ComponentError>> {
        init();
        window.Telegram.WebApp.enableClosingConfirmation();
        window.Telegram.WebApp.disableVerticalSwipes();
        window.Telegram.WebApp.expand();
        window.Telegram.WebApp.setBackgroundColor('#0048A8');
        window.Telegram.WebApp.setHeaderColor('#0048A8');
        window.Telegram.WebApp.setBottomBarColor('#0048A8');

        const isLandscape = window.innerWidth > window.innerHeight;
        if (isLandscape) {
            return [
                new ComponentError(
                    ComponentErrorType.LoadError,
                    'landscapeModeNotSupported'.tr()
                ),
            ];
        }

        // Lock after we made sure that the screen is in portrait mode
        window.Telegram.WebApp.lockOrientation();

        const navComponent = ContainerHelper.forceGetNavigationComponent();
        const initialSearchParams =
            await navComponent.initialSearchParamsCompleter.promise;

        const friend =
            initialSearchParams.get(
                search_params_keys.SEARCH_PARAMS_KEY_FRIEND_REFERER
            ) || undefined;
        const referer =
            initialSearchParams.get(
                search_params_keys.SEARCH_PARAMS_KEY_REFERER
            ) || undefined;

        const initData = window.Telegram.WebApp.initData;
        const startParam = friend || referer;

        if (!initData) {
            return [
                new ComponentError(
                    ComponentErrorType.LoadError,
                    'telegramInitializationDataNotFound'.tr()
                ),
            ];
        }

        let validationResult = await this._validate(
            initData,
            this._usernameCreationParameter,
            startParam
        );

        if (!validationResult.isValid) {
            return [
                new ComponentError(
                    ComponentErrorType.LoadError,
                    validationResult.message || 'unknownError'.tr()
                ),
            ];
        }

        this._authToken = validationResult.token;
        this._isNewUser = validationResult.isNewUser;

        ContainerHelper.getSignalRComponent().then((signalRComponent) => {
            signalRComponent.registerMethodHandler('Refresh', (...args) => {
                window.location.reload();
            });
        });

        return [];
    }

    private async _validate(
        initData: string,
        username?: string,
        referrerTelegramId?: string
    ): Promise<TelegramValidationResult> {
        const response = await TelegramApi.validate(
            initData,
            username,
            referrerTelegramId
        );
        if (response.isSuccess) {
            return new TelegramValidationResult({
                isValid: true,
                token: response.response['token'],
                isNewUser: response.response['isNewUser'],
            });
        } else {
            return new TelegramValidationResult({
                isValid: false,
                message: response.errorMessage || 'unknownError'.tr(),
            }); // TODO: tr()
        }
    }

    async validateUsername(
        username: string
    ): Promise<UsernameValidationResult> {
        const response = await TelegramApi.validateUsername(username);
        if (response.isSuccess) {
            return new UsernameValidationResult({
                isValid: true,
            });
        } else {
            return new UsernameValidationResult({
                isValid: false,
                message: response.errorMessage || 'unknownError'.tr(),
            });
        }
    }

    async notifyAppClosed(): Promise<void> {
        await TelegramApi.notifyAppClosed();
    }

    get name(): string {
        return 'Telegram Component';
    }

    onPause(): Promise<void> {
        return Promise.resolve(undefined);
    }

    onResume(): Promise<void> {
        return Promise.resolve(undefined);
    }

    onUnload(): Promise<void> {
        return Promise.resolve(undefined);
    }

    update(sinceLastUpdate: TimeDuration): void {}

    get type(): Function {
        return TelegramComponent;
    }
}

export default TelegramComponent;
