export enum ItemOwnerType {
    Default = 0,
    Skin = 1,
    User = 2,
}

export enum ItemType {
    Body = 0,
    Axe = 1,
    Bag = 2,
    Bottom = 3,
    Bracelet = 4,
    Earring = 5,
    Eye = 6,
    Eyebrow = 7,
    Eyewear = 8,
    Glove = 9,
    Hair = 10,
    HairAcc = 11,
    HandAcc = 12,
    Head = 13,
    Headgear = 14,
    Lips = 15,
    Mask = 16,
    Mustache = 17,
    Shield = 18,
    Shoes = 19,
    Spear = 20,
    Sword = 21,
    Top = 22,
    Watch = 23,
    Skin = 24,
}

export enum ItemCompatibility {
    All = 0,
    Except = 1,
    Only = 2,
    None = 3,
}

export enum ItemRarity {
    Common = 0,
    Rare = 1,
    Epic = 2,
    Legendary = 3,
}

export interface ItemCompact {
    /**
     * @type {ItemType}
     * @memberof ItemCompact
     */
    type: ItemType;

    /**
     * @type {number}
     * @memberof ItemCompact
     */
    index: number;
}

/**
 * @export
 * @interface Item
 */
export class Item {
    /**
     * @type {string}
     * @memberof Item
     */
    id?: string | null;

    /**
     * @type {string}
     * @memberof Item
     */
    imagePath?: string | null;

    /**
     * @type {ItemOwnerType}
     * @memberof Item
     */
    itemOwnerType: ItemOwnerType;

    /**
     * @type {ItemType}
     * @memberof Item
     */
    type: ItemType;

    /**
     * @type {ItemCompatibility}
     * @memberof Item
     */
    compatibility: ItemCompatibility;

    /**
     * @type {string}
     * @memberof Item
     */
    name: string;

    /**
     * @type {string}
     * @memberof Item
     */
    description: string;

    /**
     * @type {ItemRarity}
     * @memberof Item
     */
    rarity: ItemRarity;

    /**
     * @type {number}
     * @memberof Item
     */
    index: number;

    /**
     * @type {Array<ItemCompact>}
     * @memberof Item
     */
    onlyOrExcept: ItemCompact[];

    constructor(item: { [key: string]: any }) {
        this.id = item['id'] as string | null;
        this.imagePath = item['imagePath'] as string | null;
        this.itemOwnerType = item['itemOwnerType'] as ItemOwnerType;
        this.type = item['type'] as ItemType;
        this.compatibility = item['compatibility'] as ItemCompatibility;
        this.name = item['name'] as string;
        this.description = item['description'] as string;
        this.rarity = item['rarity'] as ItemRarity;
        this.index = item['index'] as number;
        this.onlyOrExcept = item['onlyOrExcept'] as ItemCompact[];
    }

    static fromJsonLowerCamelCase(json: any): Item {
        return new Item({
            id: json['id'],
            imagePath: json['imagePath'],
            itemOwnerType: json['itemOwnerType'],
            type: json['type'],
            compatibility: json['compatibility'],
            name: json['name'],
            description: json['description'],
            rarity: json['rarity'],
            index: json['index'],
            onlyOrExcept: json['onlyOrExcept'],
        });
    }

    static fromJsonUpperCamelCase(json: any): Item {
        return new Item({
            id: json['Id'],
            imagePath: json['ImagePath'],
            itemOwnerType: json['ItemOwnerType'],
            type: json['Type'],
            compatibility: json['Compatibility'],
            name: json['Name'],
            description: json['Description'],
            rarity: json['Rarity'],
            index: json['Index'],
            onlyOrExcept: json['OnlyOrExcept'],
        });
    }
}

export interface RawItem
    extends Omit<Item, 'itemOwnerType' | 'type' | 'rarity'> {
    itemOwnerType: number;
    type: number;
    rarity: number;
}

/**
 * Converts a raw item from the backend (with numeric enum values) to an Item with proper enum types
 * @param rawItem The raw item data from the backend
 * @returns An Item with properly typed enums
 */
export function convertRawItem(rawItem: RawItem): Item {
    return {
        ...rawItem,
        itemOwnerType: rawItem.itemOwnerType as ItemOwnerType,
        type: rawItem.type as ItemType,
        rarity: rawItem.rarity as ItemRarity,
    };
}

/**
 * Converts an array of raw items from the backend to Items with proper enum types
 * @param rawItems Array of raw item data from the backend
 * @returns Array of Items with properly typed enums
 */
export function convertRawItems(rawItems: RawItem[]): Item[] {
    return rawItems.map(convertRawItem);
}

/**
 * Gets the string representation of an ItemOwnerType
 * @param type The ItemOwnerType enum value
 * @returns The string representation of the ItemOwnerType
 */
export function getItemOwnerTypeString(type: ItemOwnerType): string {
    return ItemOwnerType[type];
}

/**
 * Gets the string representation of an ItemType
 * @param type The ItemType enum value
 * @returns The string representation of the ItemType
 */
export function getItemTypeString(type: ItemType): string {
    return ItemType[type];
}

/**
 * Gets the string representation of an ItemRarity
 * @param rarity The ItemRarity enum value
 * @returns The string representation of the ItemRarity
 */
export function getItemRarityString(rarity: ItemRarity): string {
    return ItemRarity[rarity];
}
