import React, { forwardRef, useState } from 'react';
import styles from '../../pages/vault_page.module.css';
import Images from '../../../assets/images/icons/diggers_go_coin.png';
import formatNumber from '../../../utils/currency_formatter';
import CurrencyIcon, { CurrencyType } from '../currency_icon';
import AcquiredOffer from '../../../apis/offer_api/acquired_offer';
import VaultOfferType from '../../../apis/offer_api/vault_offer_type';

export type VaultListItemProps = {
    icon?: string;
    title: string;
    amount?: number | string;
    cryptoCurrency?: string;
    onClick?: () => void;
    canClaim?: boolean;
    status?: string;
    disabled?: boolean;
    acquiredOffer?: AcquiredOffer;
    isNew?: undefined | boolean;
};

const VaultListItem = forwardRef<HTMLDivElement, VaultListItemProps>(
    (
        {
            icon = Images,
            title,
            amount,
            cryptoCurrency,
            onClick,
            canClaim = true,
            status,
            disabled = false,
            acquiredOffer,
            isNew,
        },
        ref
    ) => {
        const [isImageLoaded, setIsImageLoaded] = useState(false);

        return (
            <div
                ref={ref}
                data-is-new={isNew ? 'true' : 'false'}
                className={`${styles.questPanel} ${disabled ? styles.noCoins : ''}`}
            >
                <img
                    src={icon}
                    className={styles.icon}
                    onLoad={() => setIsImageLoaded(true)}
                    onError={() => setIsImageLoaded(true)}
                    alt=""
                />
                <h5 className={styles.tokenTitle}>{title}</h5>
                <div className={styles.actions}>
                    {status ? (
                        <div className={styles.status}>
                            <p>{status}</p>
                        </div>
                    ) : (
                        <>
                            {canClaim && (
                                <button
                                    type="button"
                                    className={`${styles.claim} ${disabled ? styles.noCoins : ''}`}
                                    onClick={onClick}
                                    disabled={disabled || !isImageLoaded}
                                >
                                    Claim
                                </button>
                            )}
                            {acquiredOffer?.activationCost && (
                                <p>
                                    <CurrencyIcon
                                        cryptoCurrency={
                                            acquiredOffer.activationCost
                                                .cryptoCurrency
                                        }
                                        size={20}
                                    />
                                    {formatNumber(
                                        acquiredOffer.activationCost
                                            .cryptoCurrencyAmount
                                    )}
                                </p>
                            )}

                            {acquiredOffer &&
                                acquiredOffer.vaultOfferType ===
                                    VaultOfferType.LootBox && (
                                    <button
                                        type="button"
                                        className={`${styles.claim} ${!acquiredOffer.activationCost ? styles.noCoins : ''}`}
                                        onClick={onClick}
                                        disabled={disabled || !isImageLoaded}
                                    >
                                        Open
                                    </button>
                                )}
                        </>
                    )}
                </div>
                {isNew && <span className={styles.questPanelBadge}>!</span>}
            </div>
        );
    }
);

export default VaultListItem;
