import React, { useState, useEffect, useCallback } from 'react';
import ReactModal from 'react-modal';
import styles from './loot_box_modal.module.css';
import Images from '../../../component_system/components/preload/images';
import { constants, rarity_gradient } from '../../../utils/constants';
import CustomizationApi from '../../../apis/customization_api/customization_api';
import AcquiredOffer from '../../../apis/offer_api/acquired_offer';
import LootBoxInformation from '../../../apis/customization_api/loot_box_information';
import { Item, ItemRarity } from '../../../apis/customization_api/item';
import CompactJsonOffer from '../../../apis/offer_api/compact_json_offer';
import CurrencyIcon from '../currency_icon';
import formatNumber from '../../../utils/currency_formatter';
import ContainerHelper from '../../../component_system/component_container/utilities/container_helper';
import useFetchComponent from '../../../component_system/component_container/utilities/use_fetch_hook';
import { toast } from 'react-toastify';
import LootBoxSpinner from './loot_box_spinner';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';

const ITEM_IMAGE_URL_STRUCTURE = constants.ITEM_IMAGE_URL_STRUCTURE;

interface LootBoxModalProps {
    show: boolean;
    onClose: () => void;
    offer: AcquiredOffer;
}

const getRarityColor = (rarity: ItemRarity) => {
    switch (rarity) {
        case ItemRarity.Common:
            return rarity_gradient.Common;
        case ItemRarity.Rare:
            return rarity_gradient.Rare;
        case ItemRarity.Epic:
            return rarity_gradient.Epic;
        case ItemRarity.Legendary:
            return rarity_gradient.Legendary;
        default:
            return rarity_gradient.Common;
    }
};

const getRarityGradient = (rarity: ItemRarity) => {
    switch (rarity) {
        case ItemRarity.Common:
            return rarity_gradient.Common;
        case ItemRarity.Rare:
            return rarity_gradient.Rare;
        case ItemRarity.Epic:
            return rarity_gradient.Epic;
        case ItemRarity.Legendary:
            return rarity_gradient.Legendary;
        default:
            return rarity_gradient.Common;
    }
};

const getRarity = (rarity: string | ItemRarity | undefined): ItemRarity => {
    if (!rarity) {
        return ItemRarity.Common;
    }

    // Check if it's already an ItemRarity enum
    if (typeof rarity === 'number') {
        return rarity;
    }

    rarity = rarity.charAt(0).toUpperCase() + rarity.slice(1).toLowerCase();
    switch (rarity) {
        case 'Common':
            return ItemRarity.Common;
        case 'Rare':
            return ItemRarity.Rare;
        case 'Epic':
            return ItemRarity.Epic;
        case 'Legendary':
            return ItemRarity.Legendary;
        default:
            return ItemRarity.Common;
    }
};

const LootBoxModal: React.FC<LootBoxModalProps> = ({
    show,
    onClose,
    offer,
}) => {
    const [opening, setOpening] = useState(false);
    const [loading, setLoading] = useState(true);
    const [info, setInfo] = useState<LootBoxInformation | null>(null);
    const [allItems, setAllItems] = useState<(Item | CompactJsonOffer)[]>([]);
    const [winning, setWinning] = useState<AcquiredOffer | Item | null>(null);
    const [winningItem, setWinningItem] = useState<Item | null>(null);
    const [winningOffer, setWinningOffer] = useState<AcquiredOffer | null>(
        null
    );

    const offerComponent = useFetchComponent(
        ContainerHelper.getOffersComponent
    );

    useEffect(() => {
        if (show && offer) {
            const getRarity = (
                rarity: string | ItemRarity | undefined
            ): ItemRarity => {
                if (!rarity) {
                    return ItemRarity.Common;
                }

                // Check if it's already an ItemRarity enum
                if (typeof rarity === 'number') {
                    return rarity;
                }

                rarity =
                    rarity.charAt(0).toUpperCase() +
                    rarity.slice(1).toLowerCase();
                switch (rarity) {
                    case 'Common':
                        return ItemRarity.Common;
                    case 'Rare':
                        return ItemRarity.Rare;
                    case 'Epic':
                        return ItemRarity.Epic;
                    case 'Legendary':
                        return ItemRarity.Legendary;
                    default:
                        return ItemRarity.Common;
                }
            };

            setLoading(true);
            CustomizationApi.getLootBoxInfo(offer.uniqueId)
                .then((response) => {
                    setInfo(response);
                    // setWinning(response.possibleItems[0]); // TODO: Remove this line
                    // setWinningItem(response.possibleItems[0]); // TODO: Remove this line
                    const all = [
                        ...response.possibleItems,
                        ...response.possibleOffers,
                    ];
                    // Sort by rarity (string) (first convert to ItemRarity enum)
                    all.sort((a, b) =>
                        getRarity(a.rarity).valueOf() >
                        getRarity(b.rarity).valueOf()
                            ? -1
                            : 1
                    );

                    setAllItems(all);
                })
                .catch((error) => {
                    console.error('Error fetching loot box info:', error);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [show, offer]);

    const renderPossibleItem = (item: Item) => (
        <div
            key={item.id}
            className={styles.itemCard}
            style={{
                background: getRarityColor(item.rarity),
            }}
        >
            {item.imagePath && (
                <img
                    src={ITEM_IMAGE_URL_STRUCTURE.replace(
                        '%ITEM_ID%',
                        item.id!
                    )}
                    alt={item.name}
                    className={styles.itemImage}
                />
            )}
            <div className={styles.itemInfo}>
                <h3 className={styles.itemName}>{item.name}</h3>
                <p className={styles.itemDescription}>{item.description}</p>
                <span className={styles.itemRarity}>
                    {ItemRarity[item.rarity]}
                </span>
            </div>
        </div>
    );

    const renderPossibleOffer = (offer: CompactJsonOffer) => {
        return (
            <div
                key={offer.offerId}
                className={styles.offerCard}
                style={{
                    // capitalize the rarity and use it as a key to get the gradient
                    background: getRarityGradient(
                        getRarity(
                            offer.rarity
                                ? String(offer.rarity).charAt(0).toUpperCase() +
                                      String(offer.rarity)
                                          .slice(1)
                                          .toLowerCase()
                                : 'Common'
                        )
                    ),
                }}
            >
                <img
                    src={offer.coverUrl || offer.url}
                    alt={offer.name}
                    className={styles.offerImage}
                />
                <div className={styles.offerInfo}>
                    <h3 className={styles.offerName}>{offer.name}</h3>
                    <div className={styles.offerCost}>
                        <span>
                            {offer.rarity
                                ? String(offer.rarity).charAt(0).toUpperCase() +
                                  String(offer.rarity).slice(1).toLowerCase()
                                : 'Common'}
                        </span>
                    </div>
                </div>
            </div>
        );
    };

    const renderPossibleAcquiredOffer = (acquiredOffer: AcquiredOffer) => {
        return (
            <div
                key={acquiredOffer.nonUniqueId}
                className={styles.offerCard}
                style={{
                    // capitalize the rarity and use it as a key to get the gradient
                    background: getRarityGradient(
                        getRarity(
                            acquiredOffer.offerRarity
                                ? String(acquiredOffer.offerRarity)
                                      .charAt(0)
                                      .toUpperCase() +
                                      String(acquiredOffer.offerRarity)
                                          .slice(1)
                                          .toLowerCase()
                                : 'Common'
                        )
                    ),
                }}
            >
                <img
                    src={acquiredOffer.coverUrl}
                    alt={acquiredOffer.offerTitle}
                    className={styles.offerImage}
                />
                <div className={styles.itemInfo}>
                    <h3 className={styles.itemName}>
                        {acquiredOffer.cryptoCurrency
                            ? `${formatNumber(acquiredOffer.cryptoCurrencyAmount)} ${acquiredOffer.cryptoCurrency}`
                            : acquiredOffer.offerTitle}
                    </h3>
                    <p className={styles.itemDescription}>
                        {acquiredOffer.description}
                    </p>
                    <span className={styles.itemRarity}>
                        {acquiredOffer.offerRarity
                            ? String(acquiredOffer.offerRarity)
                                  .charAt(0)
                                  .toUpperCase() +
                              String(acquiredOffer.offerRarity)
                                  .slice(1)
                                  .toLowerCase()
                            : 'Common'}
                    </span>
                </div>
            </div>
        );
    };

    const renderPossible = useCallback(
        (item: Item | CompactJsonOffer | AcquiredOffer) => {
            if (item instanceof Item) {
                return renderPossibleItem(item);
            } else if (item instanceof CompactJsonOffer) {
                return renderPossibleOffer(item);
            } else {
                return renderPossibleAcquiredOffer(item);
            }
        },
        []
    );

    const handleOpen = useCallback(async () => {
        if (!offerComponent) {
            return;
        }

        setOpening(true);

        const [error, errorMessage, acquiredOffer, item] =
            await offerComponent.openLootBox(offer.uniqueId);

        if (errorMessage || error) {
            toast.error(errorMessage);
            setOpening(false);
            return;
        }

        setWinning(acquiredOffer || item!);
        setWinningItem(item || null);
        setWinningOffer(acquiredOffer || null);
        setOpening(false);
    }, [offer.uniqueId, offerComponent]);

    const MySwal = withReactContent(Swal);

    const handleDone = useCallback(async () => {
        await MySwal.fire({
            title: 'You won:',
            customClass: {
                popup: styles.container,
                title: styles.title,
                confirmButton: styles.confirmButton,
                cancelButton: styles.cancelButton,
                container: styles.swalBackdrop,
            },
            showCancelButton: false,
            confirmButtonText: 'Claim',
            html: <>{renderPossible(winningItem || winningOffer!)}</>,
        });
        onClose();
    }, [MySwal, onClose, renderPossible, winningItem, winningOffer]);

    return (
        <ReactModal
            isOpen={show}
            onRequestClose={onClose}
            shouldCloseOnOverlayClick={!winning}
            style={{
                overlay: {
                    zIndex: 3,
                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                },
                content: {
                    transition: 'all 0.3s ease-in-out',
                    background: `url(${Images.Backgrounds.Background}) center center / cover no-repeat`,
                    border: '1px solid var(--primary-color)',
                    borderRadius: winning ? '0px' : '8px',
                    padding: '0px',
                    inset: winning ? '0px' : '40px',
                    overflow: 'hidden',
                },
            }}
        >
            <div className={styles.modalContent}>
                <div className={styles.header}>
                    <h2 className={styles.title}>{offer.offerTitle}</h2>
                    {!winning && (
                        <button
                            className={styles.closeButton}
                            onClick={onClose}
                        >
                            ×
                        </button>
                    )}
                </div>

                {loading ? (
                    <div className={styles.loading}>
                        <img
                            src={Images.Icons.Loader}
                            alt="Loading..."
                            className={styles.spinner}
                        />
                        <p>Loading loot box contents...</p>
                    </div>
                ) : info ? (
                    <div className={styles.content}>
                        <div className={styles.activationSection}>
                            {offer.activationCost && (
                                <div className={styles.activationCost}>
                                    <p>Activation Cost:</p>
                                    <div className={styles.costAmount}>
                                        <CurrencyIcon
                                            cryptoCurrency={
                                                offer.activationCost
                                                    .cryptoCurrency
                                            }
                                            size={24}
                                        />
                                        <span>
                                            {formatNumber(
                                                offer.activationCost
                                                    .cryptoCurrencyAmount
                                            )}
                                        </span>
                                    </div>
                                </div>
                            )}
                            <button
                                className={styles.openButton}
                                onClick={handleOpen}
                                disabled={!!winning || opening}
                            >
                                Open Now
                            </button>
                        </div>

                        {!winning && allItems?.length > 0 && (
                            <section className={styles.section}>
                                <h3 className={styles.sectionTitle}>
                                    Possible Rewards
                                </h3>
                                <div className={styles.itemsGrid}>
                                    {allItems.map(renderPossible)}
                                </div>
                            </section>
                        )}

                        {winning && allItems?.length > 0 && (
                            <LootBoxSpinner
                                key={offer.uniqueId}
                                allItems={allItems}
                                winning={winningItem || winningOffer!}
                                onSpinComplete={handleDone}
                            />
                        )}
                    </div>
                ) : (
                    <div className={styles.error}>
                        <p>Failed to load loot box information</p>
                    </div>
                )}
            </div>
        </ReactModal>
    );
};

export default LootBoxModal;
