import React, { useCallback, useEffect, useRef, useState } from 'react';
import styles from './vault_page.module.css';
import useFetchComponent from '../../component_system/component_container/utilities/use_fetch_hook';
import ContainerHelper from '../../component_system/component_container/utilities/container_helper';
import OfferComponent from '../../component_system/components/offer/offer_component';
import ValueContainer from '../../utils/value_container';
import useObservable from '../../utils/observable/use_observable_hook';
import HapticButton from '../components/button/haptic_button';
import Images from '../../component_system/components/preload/images';
import CryptoCurrencyInfo from '../../apis/offer_api/crypto_currency_info';
import { showSweetAlert } from '../components/popup/sweet_alert';
import formatNumber from '../../utils/currency_formatter';
import ClaimedOffer, { ClaimStatus } from '../../apis/offer_api/claimed_offers';
import Decimal from 'decimal.js';
import WalletType from '../../apis/models/profile/wallet_type';
import VaultListItem from '../components/vault/vault_list_item';
import VaultOfferType from '../../apis/offer_api/vault_offer_type';
import LootBoxModal from '../components/loot_box/loot_box_modal';
import AcquiredOffer from '../../apis/offer_api/acquired_offer';
import { constants } from '../../utils/constants';
import { useLocation } from 'react-router-dom';

const VaultPage = () => {
    const { state } = useLocation();
    const itemRefs = useRef<(HTMLDivElement | null)[]>([]);
    const otherItemRefs = useRef<(HTMLDivElement | null)[]>([]);

    const MIN_CLAIM_LEVEL = 50;

    const navigationComponent = useFetchComponent(
        ContainerHelper.getNavigationComponent
    );

    const coins = useObservable(ValueContainer.coinsObservable);
    const currentLevel = useObservable(ValueContainer.currentLevelObservable);

    const evmWallet = useObservable(ValueContainer.evmWalletObservable);
    const tonWallet = useObservable(ValueContainer.tonWalletObservable);
    const solanaWallet = useObservable(ValueContainer.solanaWalletObservable);

    const [aquiredOffers, setAquiredOffers] = useState<CryptoCurrencyInfo[]>(
        []
    );
    const [otherAquiredOffers, setOtherAquiredOffers] = useState<
        AcquiredOffer[]
    >([]);
    const [claimedOffers, setClaimedOffers] = useState<ClaimedOffer[]>([]);
    const [totalWorth, setTotalWorth] = useState<Decimal>(new Decimal(0));
    const offerComponentRef = useRef<OfferComponent | null>(null);

    const [selectedLootBox, setSelectedLootBox] =
        useState<AcquiredOffer | null>(null);

    const offerComponent = useFetchComponent(
        ContainerHelper.getOffersComponent
    );

    useEffect(() => {
        if (state?.acquiredOffer) {
            const acquiredOffer = state.acquiredOffer as AcquiredOffer;
            if (acquiredOffer.vaultOfferType === VaultOfferType.LootBox) {
                setSelectedLootBox(acquiredOffer);
            }
        }

        const subscriber = () => {
            if (offerComponentRef.current) {
                setAquiredOffers([...offerComponentRef.current.acquiredOffers]);
                setOtherAquiredOffers([
                    ...offerComponentRef.current.otherAcquiredOffers,
                ]);
                setClaimedOffers([...offerComponentRef.current.claims]);
                setTotalWorth(offerComponentRef.current.totalWorth);
            }
        };

        const setup = async () => {
            offerComponentRef.current =
                await ContainerHelper.getOffersComponent();
            // Subscribe to updates
            offerComponentRef.current.registerSubscriber(subscriber);
            setAquiredOffers([...offerComponentRef.current.acquiredOffers]);
            setOtherAquiredOffers([
                ...offerComponentRef.current.otherAcquiredOffers,
            ]);
            setClaimedOffers([...offerComponentRef.current.claims]);
            setTotalWorth(offerComponentRef.current.totalWorth);
        };

        setup();

        // Cleanup on unmount
        return () => {
            if (offerComponentRef.current) {
                offerComponentRef.current.unregisterSubscriber(subscriber);
            }
        };
    }, []);

    function claimAction(offer: CryptoCurrencyInfo): void {
        const result = canClaim(offer, true);
        if (!result.canClaim) {
            showSweetAlert('info', 'Claim', result.errorMessage ?? 'Error');
            return;
        }
        offerComponentRef.current?.claim(offer.cryptoCurrency);

        //showSweetAlert(
        //         'info',
        //        'Claim',
        //        `Please wait for the claim feature to go live.`
        //    )
    }

    function canClaim(
        offer: CryptoCurrencyInfo,
        checkWallet: boolean
    ): { canClaim: boolean; errorMessage?: string } {
        if ((offer.cryptoCurrencyAmount ?? 0) <= 0)
            return { canClaim: false, errorMessage: 'No coins to claim' };
        if (offer.cryptoCurrency === 'DIG') {
            return {
                canClaim: false,
                errorMessage: 'DIG is not claimable yet',
            };
        }
        if ((currentLevel ?? 0) < MIN_CLAIM_LEVEL) {
            return {
                canClaim: false,
                errorMessage: `You can claim at level ${MIN_CLAIM_LEVEL} with ${offer.minClaimableUSDAmount} USD worth of ${offer.cryptoCurrency}.`,
            };
        }
        if (!enoughCoins(offer)) {
            return {
                canClaim: false,
                errorMessage: `To claim you need ${offer.minClaimableUSDAmount} USD worth of ${offer.cryptoCurrency}.`,
            };
        }
        if (checkWallet && !isWalletSet(offer)) {
            return {
                canClaim: false,
                errorMessage: `Please set your wallet for ${offer.blockchainType} to claim ${offer.cryptoCurrency}.`,
            };
        }
        return { canClaim: true };
    }

    function enoughCoins(offer: CryptoCurrencyInfo): boolean {
        switch (offer.cryptoCurrency) {
            case 'DIG':
                return false;
            default:
                return new Decimal(offer.cryptoCurrencyAmount ?? 0)
                    .mul(offer.quotePriceUSD)
                    .gte(offer.minClaimableUSDAmount);
        }
    }

    function isWalletSet(offer: CryptoCurrencyInfo): boolean {
        switch (offer.blockchainType.toLocaleLowerCase()) {
            case WalletType.EVM:
                return (
                    evmWallet !== undefined &&
                    evmWallet !== null &&
                    evmWallet !== ''
                );
            case WalletType.TON:
                return (
                    tonWallet !== undefined &&
                    tonWallet !== null &&
                    tonWallet !== ''
                );
            case WalletType.Solana:
                return (
                    solanaWallet !== undefined &&
                    solanaWallet !== null &&
                    solanaWallet !== ''
                );
            default:
                return false;
        }
    }

    function statusMessage(claim: ClaimedOffer): string {
        switch (claim.status) {
            case ClaimStatus.Completed:
                return 'Your request has been successfully processed and paid out!';
            case ClaimStatus.Requested:
                return 'Your request is pending. Please allow up to 24 hours. Thank you!';
            case ClaimStatus.Processing:
                return 'Your request is being processed. Please allow up to 24 hours. Thank you!';
            case ClaimStatus.Rejected:
                return 'Your request was denied';
            case ClaimStatus.WaitingUserAction:
                return 'Please visit our website to complete the claim process';
            default:
                return 'Unknown';
        }
    }

    const openClaimUrl = () => {
        console.log('openClaimUrl', constants.CLAIM_URL);
        window.open(constants.CLAIM_URL, '_blank');
    };

    const scrollToNew = useCallback(() => {
        // Find the first item with `isNew` from both lists
        const allRefs = [...itemRefs.current, ...otherItemRefs.current];
        const newItemIndex = allRefs.findIndex(
            (ref) => ref?.dataset?.isNew === 'true'
        );
        if (newItemIndex !== -1 && allRefs[newItemIndex]) {
            const element = allRefs[newItemIndex]!;
            const y = element.getBoundingClientRect().top + window.scrollY;
            document.getElementById('scrollable-container')?.scroll(0, y);
        }
    }, []);

    useEffect(() => {
        scrollToNew();
    }, [aquiredOffers, otherAquiredOffers, scrollToNew]);

    const handleLootBoxClose = useCallback(() => {
        setSelectedLootBox(null);
        ContainerHelper.getOffersComponent().then((offersComponent) => {
            offersComponent.markNewAcquisitionAsSeen();
        });
        scrollToNew();
    }, [scrollToNew]);

    return (
        <>
            <div className={styles.pageBg}>
                <img
                    src={Images.Backgrounds.AnimatedBackground}
                    className={styles.animatedImage}
                    alt=""
                />
            </div>
            <div className={styles.pageContent} id="scrollable-container">
                <h2 className={styles.pageTitle}>Vault</h2>

                <div key={-1} className={`${styles.questPanel} `}>
                    <img
                        src={Images.Icons.DiggersGoCoin}
                        className={styles.icon}
                        alt=""
                    />
                    <h5 className={styles.tokenTitle}>{'DIG'}</h5>
                    <div className={styles.actions}>
                        <p>{formatNumber(coins)}</p>
                        <HapticButton
                            hapticFeedbackType="impact"
                            hapticFeedbackStyle="light"
                            //  disabled={true}
                            type="button"
                            className={`${styles.claim} ${styles.noCoins}`}
                            onClick={() =>
                                claimAction({
                                    cryptoCurrency: 'DIG',
                                    name: '',
                                    description: '',
                                    coverUrl: '',
                                    blockchainType: '',
                                    network: '',
                                    quotePriceUSD: new Decimal(0),
                                    cryptoCurrencyAmount: coins,
                                    minClaimableUSDAmount: new Decimal(0),
                                })
                            }
                        >
                            Claim
                        </HapticButton>
                    </div>

                    {offerComponentRef.current?.newAcquisition &&
                        offerComponentRef.current?.newAcquisition
                            .cryptoCurrency === 'DIG' && (
                            <span className={styles.questPanelBadge}>!</span>
                        )}
                </div>

                {aquiredOffers && aquiredOffers.length > 0
                    ? aquiredOffers
                          .filter((o) => o.cryptoCurrency !== 'DIG')
                          .map((offer, index) => (
                              <div
                                  key={`offer-${index}`}
                                  ref={(el) => (itemRefs.current[index] = el)}
                                  data-is-new={
                                      offerComponentRef.current
                                          ?.newAcquisition &&
                                      offerComponentRef.current?.newAcquisition
                                          .cryptoCurrency ===
                                          offer.cryptoCurrency
                                          ? 'true'
                                          : 'false'
                                  }
                                  className={`${styles.questPanel} ${styles.vault} ${offer.name === undefined ? styles.nOCoin : ''}`}
                              >
                                  <img
                                      src={
                                          offer.coverUrl ||
                                          Images.Icons.DiggersGoCoin
                                      }
                                      className={styles.icon}
                                      alt=""
                                  />
                                  <h5 className={styles.tokenTitle}>
                                      {offer.cryptoCurrency}
                                  </h5>
                                  <div className={styles.actions}>
                                      <p>
                                          {`${formatNumber(offer.cryptoCurrencyAmount)}`}{' '}
                                          {/* (${formatTokenValue(offer.quotePriceUSD.mul(new Decimal(offer.cryptoCurrencyAmount??0)))}) */}
                                      </p>
                                      <HapticButton
                                          hapticFeedbackType="impact"
                                          hapticFeedbackStyle="light"
                                          type="button"
                                          disabled={
                                              (offer.cryptoCurrencyAmount ??
                                              0 > 0)
                                                  ? false
                                                  : true
                                          }
                                          className={`${styles.claim} ${canClaim(offer, false).canClaim ? '' : styles.noCoins}`}
                                          onClick={() => claimAction(offer)}
                                      >
                                          Claim
                                      </HapticButton>
                                  </div>

                                  {offerComponentRef.current?.newAcquisition &&
                                      offerComponentRef.current?.newAcquisition
                                          .cryptoCurrency ===
                                          offer.cryptoCurrency && (
                                          <span
                                              className={styles.questPanelBadge}
                                          >
                                              !
                                          </span>
                                      )}
                              </div>
                          ))
                    : null}

                {/* Other Acquired Offers */}
                {offerComponent?.otherAcquiredOffers && (
                    <>
                        <div className={styles.divider} />
                        <p className={styles.label}>Other Rewards</p>
                        {offerComponent.otherAcquiredOffers
                            .filter((o) => !o.isUsed && !o.isExpired())
                            .sort((a, b) => {
                                return (
                                    new Date(b.acquiredAtDateUtc).getTime() -
                                    new Date(a.acquiredAtDateUtc).getTime()
                                );
                            })
                            .map((offer, index) => {
                                const isNew =
                                    offerComponentRef.current?.newAcquisition &&
                                    offerComponentRef.current?.newAcquisition
                                        .uniqueId === offer.uniqueId;
                                return (
                                    <VaultListItem
                                        acquiredOffer={offer}
                                        key={`other-${index}`}
                                        ref={(el) =>
                                            (otherItemRefs.current[index] = el)
                                        }
                                        icon={offer.coverUrl}
                                        title={offer.offerTitle}
                                        canClaim={false}
                                        isNew={isNew}
                                        onClick={() => {
                                            switch (offer.vaultOfferType) {
                                                case VaultOfferType.Crypto:
                                                    break;
                                                case VaultOfferType.LootBox:
                                                    setSelectedLootBox(offer);
                                                    break;
                                                default:
                                                    break;
                                            }
                                        }}
                                        disabled={selectedLootBox !== null}
                                    />
                                );
                            })}
                    </>
                )}

                {/*  <p className={styles.label}>{`Total worth ${formatTokenValue(totalWorth)}`}</p>  */}
                <div className={styles.divider}></div>
                <p className={styles.label}>Claims</p>
                {claimedOffers && claimedOffers.length > 0 ? (
                    claimedOffers.map((offer, index) => (
                        <div
                            key={index}
                            className={`${styles.questPanel} ${styles.vault}`}
                        >
                            <img
                                src={offer.coverUrl}
                                className={styles.icon}
                                alt=""
                            />
                            <h5
                                className={styles.tokenTitle}
                                style={{ fontSize: '20px' }}
                            >
                                {`${formatNumber(offer.cryptoCurrencyAmount)}  ${offer.cryptoCurrency}`}
                            </h5>
                            <div
                                className={styles.status}
                                style={
                                    offer.status ===
                                    ClaimStatus.WaitingUserAction
                                        ? { height: '80px' }
                                        : {}
                                }
                            >
                                <div>{statusMessage(offer)}</div>
                                {offer.status ===
                                    ClaimStatus.WaitingUserAction && (
                                    <div
                                        className={styles.actions}
                                        style={{
                                            marginTop: '8px',
                                            width: '120px',
                                        }}
                                    >
                                        <button
                                            type="button"
                                            className={styles.claim}
                                            onClick={openClaimUrl}
                                        >
                                            {'Complete Claim'}
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    ))
                ) : (
                    <p className={styles.label} style={{ fontSize: 'small' }}>
                        No claims yet
                    </p>
                )}

                <div style={{ height: 120 }} />
            </div>
            {selectedLootBox && (
                <LootBoxModal
                    show={true}
                    onClose={() => handleLootBoxClose()}
                    offer={selectedLootBox}
                />
            )}
        </>
    );
};

export default VaultPage;
