import MapBubble from '../models/map_bubble';
import MapProfile from '../models/map_profile';
import MapEvent from '../models/map_event';
import PlayerEffect from '../models/player_effect';

class PopulationResponse {
    bubbles: MapBubble[];
    mapProfiles: MapProfile[];
    mapEvents: MapEvent[];
    playerEffects: PlayerEffect[];

    constructor(params: {
        bubbles: MapBubble[];
        mapProfiles: MapProfile[];
        mapEvents: MapEvent[];
        playerEffects: PlayerEffect[];
    }) {
        this.bubbles = params.bubbles;
        this.mapProfiles = params.mapProfiles;
        this.mapEvents = params.mapEvents;
        this.playerEffects = params.playerEffects;
    }

    static fromJson(json: { [key: string]: any }): PopulationResponse {
        return new PopulationResponse({
            bubbles: (json['Bubbles'] as Array<any>).map((e: any) =>
                MapBubble.fromJson(e)
            ),
            mapProfiles: (json['MapProfiles'] as Array<any>).map((e: any) =>
                MapProfile.fromJson(e)
            ),
            mapEvents: (json['MapEvents'] as Array<any>).map((e: any) =>
                MapEvent.fromJson(e)
            ),
            playerEffects: (json['PlayerEffects'] as Array<any>).map((e: any) =>
                PlayerEffect.fromJsonUpperCamelCase(e)
            ),
        });
    }

    static fromJsonLowerCamelCase(json: {
        [key: string]: any;
    }): PopulationResponse {
        return new PopulationResponse({
            bubbles: (json['bubbles'] as Array<any>).map((e: any) =>
                MapBubble.fromJsonLowerCamelCase(e)
            ),
            mapProfiles: (json['mapProfiles'] as Array<any>).map((e: any) =>
                MapProfile.fromJsonLowerCamelCase(e)
            ),
            mapEvents: (json['mapEvents'] as Array<any>).map((e: any) =>
                MapEvent.fromJsonLowerCamelCase(e)
            ),
            playerEffects: (json['playerEffects'] as Array<any>).map((e: any) =>
                PlayerEffect.fromJsonLowerCamelCase(e)
            ),
        });
    }
}

export default PopulationResponse;
