import React, { useEffect } from 'react';
import styles from './season_pass_scroll.module.css';
import useObservable from '../../../utils/observable/use_observable_hook';
import ValueContainer from '../../../utils/value_container';
import useFetchComponent from '../../../component_system/component_container/utilities/use_fetch_hook';
import ContainerHelper from '../../../component_system/component_container/utilities/container_helper';
import Images from '../../../component_system/components/preload/images';

interface Reward {
    id: string;
    title: string;
    imageUrl: string;
    isFree?: boolean;
    status: 'locked' | 'unlocked' | 'next';
    level: number;
}

const SeasonPassScroll: React.FC = () => {
    const userLevel = useObservable(ValueContainer.currentLevelObservable);
    const customizationComponent = useFetchComponent(
        ContainerHelper.getCustomizationComponent
    );
    const [rewards, setRewards] = React.useState<Reward[]>([]);

    useEffect(() => {
        if (!customizationComponent || userLevel === undefined) return;

        const rewards: Reward[] =
            customizationComponent.seasonPassSettings!.rewards.map(
                (characterPrice) => {
                    const identifier = characterPrice.reward;
                    let imageUrl = Images.Icons.DiggersGoCoin;

                    if (characterPrice.type === 0) {
                        imageUrl = require(
                            `../../../assets/images/avatars/${characterPrice.imageAsset}`
                        );
                    }

                    return {
                        id: characterPrice.reward,
                        title: characterPrice.name,
                        level: characterPrice.level,
                        status:
                            userLevel >= characterPrice.level
                                ? 'unlocked'
                                : 'locked',
                        imageUrl: imageUrl,
                        isFree: characterPrice.isFree,
                    };
                }
            );

        // Calculate the next reward
        const nextReward = rewards.find((reward) => reward.status === 'locked');
        if (nextReward) {
            nextReward.status = 'next';
        }

        setRewards(rewards);
    }, [customizationComponent, userLevel, setRewards]);

    return (
        <div className={styles.battlePassContainer}>
            <div className={styles.battlePassScroll}>
                {rewards.map((reward) => (
                    <div
                        key={reward.id}
                        className={
                            styles.rewardItem +
                            ' ' +
                            styles[reward.status.toLowerCase()]
                        }
                    >
                        <div className={styles.rewardLevel}>
                            Level {reward.level}
                        </div>
                        <div className={styles.rewardImage}>
                            <img src={reward.imageUrl} alt={reward.title} />
                        </div>
                        <div className={styles.rewardInfo}>
                            <p className={styles.rewardTitle}>{reward.title}</p>
                            {reward.isFree && (
                                <span className={styles.rewardFree}>FREE!</span>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default SeasonPassScroll;
