import Component from '../../component_container/models/component';
import { TimeDuration, Duration } from 'typed-duration';
import AuthenticationComponent from '../authentication/authentication_component';
import SettingsApi from '../../../apis/settings_api/settings_api';
import CacheUtils from '../../../utils/cache_utils';
import RarityModel from '../../../apis/models/rarity_model';
import ContainerHelper from '../../component_container/utilities/container_helper';
import ComponentError from '../../component_container/models/component_error';
import ComponentErrorType from '../../component_container/enums/component_error_type';

class SettingsComponent extends Component {
    static readonly FOLLOW_HEADING_KEY = 'followHeading';
    static readonly FOLLOW_LOCATION_KEY = 'followLocation';
    static readonly LOCAL_SETTINGS_KEY = 'localSettings';

    private _localSettings: Map<string, any> | undefined;
    private _settings: Map<string, any> | undefined;

    private _rarityRequiredReferralCount: Map<string, number> | undefined;

    get rarityRequiredReferralCount(): Map<string, number> {
        return new Map(this._rarityRequiredReferralCount);
    }

    private _rarities: RarityModel[] | undefined;

    private _localSettingsChanged = false;

    private _sinceLastLocalSettingsSave: TimeDuration =
        Duration.milliseconds.of(0);

    getDoubleFromLocalSettings(key: string, defaultValue: number): number {
        const value = this._localSettings?.get(key);

        if (value) {
            return parseFloat(value);
        }

        return defaultValue;
    }

    getDoubleFromClientSettings(key: string, defaultValue: number): number {
        const value = this._settings?.get(key);

        if (value) {
            return parseFloat(value);
        }

        return defaultValue;
    }

    getIntFromLocalSettings(key: string, defaultValue: number): number {
        const value = this._localSettings?.get(key);

        if (value) {
            return parseInt(value);
        }

        return defaultValue;
    }

    getIntFromClientSettings(key: string, defaultValue: number): number {
        const value = this._settings?.get(key);

        if (value) {
            return parseInt(value);
        }

        return defaultValue;
    }

    getBoolFromLocalSettings(key: string, defaultValue: boolean): boolean {
        const value = this._localSettings?.get(key);

        if (typeof value === 'boolean') {
            return value;
        }

        if (typeof value === 'string') {
            return value.toLowerCase() === 'true';
        }

        return defaultValue;
    }

    getBoolFromClientSettings(key: string, defaultValue: boolean): boolean {
        const value = this._settings?.get(key);

        if (typeof value === 'boolean') {
            return value;
        }

        if (typeof value === 'string') {
            return value.toLowerCase() === 'true';
        }

        return defaultValue;
    }

    getStringFromLocalSettings(key: string, defaultValue: string): string {
        const value = this._localSettings?.get(key);

        if (value) {
            return value;
        }

        return defaultValue;
    }

    getStringFromClientSettings(key: string, defaultValue: string): string {
        const value = this._settings?.get(key);

        if (value) {
            return value;
        }

        return defaultValue;
    }

    setLocalSetting(key: string, value: any) {
        if (this._localSettings?.get(key) !== value) {
            this._localSettings?.set(key, value);
            this._localSettingsChanged = true;
        }
    }

    get type(): Function {
        return SettingsComponent;
    }

    async load() {
        await this.setDependencyLocked([AuthenticationComponent]);

        this._localSettings = this._getLocalSettings() || new Map();

        try {
            this._settings = await this._getSettings();
        } catch (error) {
            return [
                new ComponentError(
                    ComponentErrorType.LoadError,
                    'mapSettingsFailedToLoad'.tr()
                ),
            ];
        }

        this._rarities = await SettingsApi.getRarities();

        if (!this._rarities) {
            return [
                new ComponentError(
                    ComponentErrorType.LoadError,
                    'mapSettingsFailedToLoad'.tr()
                ),
            ];
        }

        this._rarityRequiredReferralCount =
            await this._getRarityRequiredReferralCount();

        if (!this._rarityRequiredReferralCount) {
            return [
                new ComponentError(
                    ComponentErrorType.LoadError,
                    'mapSettingsFailedToLoad'.tr()
                ),
            ];
        }

        ContainerHelper.getSignalRComponent().then((signalRComponent) => {
            signalRComponent.registerMethodHandler(
                'ReceiveClientSettings',
                (...args: any[]) => {
                    const settings = args[0];
                    this._localSettings = new Map(Object.entries(settings));
                    console.log('Settings updated');

                    ContainerHelper.getUnityComponent().then(
                        (unityComponent) => {
                            unityComponent.setSettings(settings);
                        }
                    );
                }
            );
        });

        return [];
    }

    get name() {
        return 'Settings Component';
    }

    async onPause() {
        return;
    }

    async onResume() {
        return;
    }

    async onUnload() {
        return;
    }

    update(sinceLastUpdate: TimeDuration) {
        this._sinceLastLocalSettingsSave = Duration.milliseconds.of(
            Duration.milliseconds.from(this._sinceLastLocalSettingsSave) +
                Duration.milliseconds.from(sinceLastUpdate)
        );

        if (
            Duration.milliseconds.from(this._sinceLastLocalSettingsSave) >=
                Duration.milliseconds.of(5000).value &&
            this._localSettingsChanged
        ) {
            this._saveLocalSettings();
            this._localSettingsChanged = false;
            this._sinceLastLocalSettingsSave = Duration.milliseconds.of(0);
            console.log('Settings saved');
        }
    }

    private async _getRarityRequiredReferralCount(): Promise<
        Map<string, number>
    > {
        const response = await SettingsApi.getRarityRequiredReferralCount();

        if (response.isSuccess) {
            return new Map(Object.entries(response.response));
        }

        return Promise.reject(response);
    }

    private async _getSettings(): Promise<Map<string, any>> {
        const response = await SettingsApi.getSettings();

        if (response.isSuccess) {
            return new Map(Object.entries(response.response));
        }

        return Promise.reject(response);
    }

    private _getLocalSettings(): Map<string, any> | undefined {
        return CacheUtils.readMapFromCache(
            SettingsComponent.LOCAL_SETTINGS_KEY
        );
    }

    private _saveLocalSettings() {
        if (this._localSettingsChanged) {
            CacheUtils.saveMapToCache(
                SettingsComponent.LOCAL_SETTINGS_KEY,
                this._localSettings!
            );
        }
    }
}

export default SettingsComponent;
