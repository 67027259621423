import Decimal from 'decimal.js';

export default function formatNumber(value: number | Decimal | undefined): string {
    if (value === undefined || value === null) {
        return '0';
    }

    let decimalValue: Decimal;

    // Convert number to Decimal if necessary
    if (typeof value === 'number') {
        decimalValue = new Decimal(value);
    } else {
        decimalValue = value;
    }

    if (decimalValue.gte(1_000_000)) {
        return decimalValue.div(1_000_000).toFixed(2) + 'M';
    } else if (decimalValue.gte(1_000)) {
        return decimalValue.div(1_000).toFixed(2) + 'K';
    } else {
        return decimalValue.toString();
    }
}

export function formatTokenValue(value: number | Decimal | undefined): string {
    if (value === undefined || value === null) {
        return '$0.00';
    }

    let decimalValue: Decimal;

    // Convert value to Decimal if it's a number
    if (typeof value === 'number') {
        decimalValue = new Decimal(value);
    } else {
        decimalValue = value;
    }

    if (decimalValue.gte(1)) {
        // For values >= $1, show two decimal places
        return `$${decimalValue.toFixed(2)}`;
    } else if (decimalValue.gte(0.01)) {
        // For values between $0.01 and $1, show four decimal places
        return `$${decimalValue.toFixed(4)}`;
    } else {
        // For values less than $0.01, show in scientific notation or more precision
        return `< $0.01 (${decimalValue.toFixed(6)})`;
    }
}