import { SeasonPassReward } from './season_pass_reward';

class SeasonPassSettings {
    currentSeason: number;
    rewards: SeasonPassReward[];

    constructor(currentSeason: number, rewards: SeasonPassReward[]) {
        this.currentSeason = currentSeason;
        this.rewards = rewards;
    }

    static fromJsonLowerCamelCase(json: any): SeasonPassSettings {
        return new SeasonPassSettings(
            json.currentSeason,
            json.rewards.map((reward: any) =>
                SeasonPassReward.fromJsonLowerCamelCase(reward)
            )
        );
    }

    static fromJsonUpperCamelCase(json: any): SeasonPassSettings {
        return new SeasonPassSettings(
            json.CurrentSeason,
            json.Rewards.map((reward: any) =>
                SeasonPassReward.fromJsonUpperCamelCase(reward)
            )
        );
    }
}

export default SeasonPassSettings;
