import ClaimedOffer from './claimed_offers';
import CryptoCurrencyInfo from './crypto_currency_info';
import AcquiredOffer from './acquired_offer';

class Vault {
    cryptoCurrencies: CryptoCurrencyInfo[];
    claimedOffers: ClaimedOffer[];
    otherAcquiredOffers: AcquiredOffer[];

    constructor({
        cryptoCurrencies,
        claimedOffers,
        otherAcquiredOffers,
    }: {
        cryptoCurrencies: CryptoCurrencyInfo[];
        claimedOffers: ClaimedOffer[];
        otherAcquiredOffers: AcquiredOffer[];
    }) {
        this.cryptoCurrencies = cryptoCurrencies;
        this.claimedOffers = claimedOffers;
        this.otherAcquiredOffers = otherAcquiredOffers;
    }

    static fromJsonLowerCamelCase(json: any): Vault {
        return new Vault({
            cryptoCurrencies: json.cryptoCurrencies.map((cryptoCurrency: any) =>
                CryptoCurrencyInfo.fromJsonLowerCamelCase(cryptoCurrency)
            ),
            claimedOffers: json.claimedOffers.map((claimedOffer: any) =>
                ClaimedOffer.fromJsonLowerCamelCase(claimedOffer)
            ),
            otherAcquiredOffers: json.otherAcquiredOffers.map(
                (acquiredOffer: any) =>
                    AcquiredOffer.fromJsonLowerCamelCase(acquiredOffer)
            ),
        });
    }

    static fromJsonUpperCase(json: any): Vault {
        return new Vault({
            cryptoCurrencies: json.CryptoCurrencies.map((cryptoCurrency: any) =>
                CryptoCurrencyInfo.fromJsonUpperCamelCase(cryptoCurrency)
            ),
            claimedOffers: json.ClaimedOffers.map((claimedOffer: any) =>
                ClaimedOffer.fromJsonUpperCase(claimedOffer)
            ),
            otherAcquiredOffers: json.OtherAcquiredOffers.map(
                (acquiredOffer: any) => AcquiredOffer.fromJson(acquiredOffer)
            ),
        });
    }
}

export default Vault;
