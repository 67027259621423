import React from 'react';
import styles from './quest_item.module.css';
import stylesChallenge from './quest_partner_challenge.module.css';
import Task from '../../../apis/models/task/task';
import Images from '../../../component_system/components/preload/images';
import TaskStatus from '../../../apis/models/task/task_status';
import TaskType from '../../../apis/models/task/task_type';
import TaskPlatform from '../../../apis/models/task/task_platform';

type QuestItemProps = {
    partnerName: string;
    task: Task;
    action: ()=>Promise<void>;
  };

const QuestItem = ({partnerName, task, action} : QuestItemProps) => {

    const questPanelClass = `${styles.questPanel} ${stylesChallenge[`${partnerName}QuestPanel`]} ${styles.vault}`;

    const getLogo = (platform: TaskPlatform) : string => {
        switch(platform){
            case TaskPlatform.Telegram:
                return Images.Icons.TelegramIcon;
            case TaskPlatform.X:
                return Images.Icons.XIcon;
            case TaskPlatform.Tiktok:
                return Images.Icons.TiktokIcon;
            case TaskPlatform.Discord:
                return Images.Icons.DiscordIcon;
            default:
                return "";
        }
    }

    return (
        <div className={questPanelClass}>
            <div className={`${styles.profileImage}`} style={{backgroundImage:`url(${getLogo(task.platform)})`}} >
                <img src={Images.Profile.ProfileOverlay} className={styles.overlay} alt="" />
                <div className={styles.backdrop}></div>
            </div>
            <div className={styles.tokenTextHolder}>
                <h5 className={`${styles.tokenTitle} ${styles.tokenTitleH5}`}>{task.description}</h5>
            </div>
            <div className={styles.actions}>
                <div className={styles.holder}>
                    {task.status===TaskStatus.Started ?
                    (
                        !task.verifiable ?
                        <>
                            <p style={{marginBottom:'1px'}}><img src={Images.Icons.DiggersGoCoin} alt="" />{task.tokens}</p>
                            <button type="button" className={`${styles.claim} ${styles.waiting}`} onClick={action}>
                                Verifying <img src={Images.Icons.Loader} className={styles.loader} alt="" />
                            </button>
                        </>
                        :
                        <>
                            <p style={{marginBottom:'1px'}}><img src={Images.Icons.DiggersGoCoin} alt="" />{task.tokens}</p>
                            <button type="button" className={`${styles.claim} ${styles.verify}`} onClick={action}>
                                Verify
                            </button>
                        </>
                    )
                    :
                    (
                        task.status===TaskStatus.NotStarted ?
                        <>
                            <p style={{marginBottom:'1px'}}><img src={Images.Icons.DiggersGoCoin} alt="" />{task.tokens}</p>
                            <button type="button" className={styles.claim} onClick={action}>
                                {task.taskType===TaskType.Follow ? 'Follow' : 'Join'}
                            </button>
                        </>
                        :
                        <>
                            <button type="button" className={`${styles.claim} ${styles.accept}`}>
                                Done
                            </button>
                        </>
                    )}
                
                </div>
            </div>
        </div>
    );
};

export default QuestItem;
