import Images from '../../component_system/components/preload/images';
import useFetchComponent from '../../component_system/component_container/utilities/use_fetch_hook';
import ContainerHelper from '../../component_system/component_container/utilities/container_helper';

export enum CurrencyType {
    Coin,
    Gem,
}

type CurrencyIconProps = {
    type?: CurrencyType;
    cryptoCurrency?: string;
    size?: number;
};

const CurrencyIcon = ({
    type,
    cryptoCurrency,
    size = 32,
}: CurrencyIconProps) => {
    const offerComponent = useFetchComponent(
        ContainerHelper.getOffersComponent
    );

    if (!offerComponent && cryptoCurrency) {
        return null;
    }

    const src =
        type !== undefined
            ? type === CurrencyType.Coin
                ? Images.Icons.DiggersGoCoin
                : Images.Icons.Star
            : cryptoCurrency
              ? offerComponent?.getCryptoCurrencyImageUri(cryptoCurrency)
              : null;

    return (
        <img
            src={src || Images.Icons.DiggersGoCoin}
            alt={type === CurrencyType.Coin ? 'Coin' : 'Gem'}
            style={{ width: size, height: size }}
        />
    );
};

export default CurrencyIcon;
