class MineClick {
    timestamp: string;
    clientX: number;
    clientY: number;
    screenX: number;
    screenY: number;
    pageX: number;
    pageY: number;

    constructor({
        timestamp,
        clientX,
        clientY,
        screenX,
        screenY,
        pageX,
        pageY,
    }: {
        timestamp: string;
        clientX: number;
        clientY: number;
        screenX: number;
        screenY: number;
        pageX: number;
        pageY: number;
    }) {
        this.timestamp = timestamp;
        this.clientX = clientX;
        this.clientY = clientY;
        this.screenX = screenX;
        this.screenY = screenY;
        this.pageX = pageX;
        this.pageY = pageY;
    }

    toJsonLowerCamelCase() {
        return {
            timestamp: this.timestamp,
            clientX: this.clientX,
            clientY: this.clientY,
            screenX: this.screenX,
            screenY: this.screenY,
            pageX: this.pageX,
            pageY: this.pageY,
        };
    }
}

export default MineClick;
