enum SeasonPassRewardType {
    Skin,
    Cosmetic,
    Offer,
}

class SeasonPassReward {
    type: SeasonPassRewardType;
    name: string;
    imageAsset: string;
    level: number;
    isFree: boolean;
    reward: string;

    constructor(
        type: SeasonPassRewardType,
        name: string,
        imageAsset: string,
        level: number,
        isFree: boolean,
        reward: string
    ) {
        this.type = type;
        this.name = name;
        this.imageAsset = imageAsset;
        this.level = level;
        this.isFree = isFree;
        this.reward = reward;
    }

    static fromJsonLowerCamelCase(json: any): SeasonPassReward {
        return new SeasonPassReward(
            json.type,
            json.name,
            json.imageAsset,
            json.level,
            json.isFree,
            json.reward
        );
    }

    static fromJsonUpperCamelCase(json: any): SeasonPassReward {
        return new SeasonPassReward(
            json.Type,
            json.Name,
            json.ImageAsset,
            json.Level,
            json.IsFree,
            json.Reward
        );
    }
}

export { SeasonPassReward, SeasonPassRewardType };
